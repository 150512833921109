$.fn.dataTable.localizedmessages = {
	de: {
		'sEmptyTable': 'Keine Daten in der Tabelle vorhanden',
		'sInfo': '_START_ bis _END_ von _TOTAL_ Einträgen',
		'sInfoEmpty': 'Keine Daten vorhanden',
		'sInfoFiltered': '(gefiltert von _MAX_ Einträgen)',
		'sInfoPostFix': '',
		'sInfoThousands': '.',
		'sLengthMenu': '_MENU_ Einträge anzeigen',
		'sLoadingRecords': 'Wird geladen ..',
		'sProcessing': 'Bitte warten ..',
		'sSearch': 'Suchen',
		'sZeroRecords': 'Keine Einträge vorhanden',
		'oPaginate': {
			'sFirst': 'Erste',
			'sPrevious': 'Zurück',
			'sNext': 'Nächste',
			'sLast': 'Letzte'
		},
		'oAria': {
			'sSortAscending': ': aktivieren, um Spalte aufsteigend zu sortieren',
			'sSortDescending': ': aktivieren, um Spalte absteigend zu sortieren'
		},
		'select': {
			'rows': {
				'_': '%d Zeilen ausgewählt',
				'0': '',
				'1': '1 Zeile ausgewählt'
			}
		},
		'buttons': {
			'print': 'Drucken',
			'colvis': 'Spalten',
			'copy': 'Kopieren',
			'copyTitle': 'In Zwischenablage kopieren',
			'copyKeys': 'Taste <i>ctrl</i> oder <i>\u2318</i> + <i>C</i> um Tabelle<br>in Zwischenspeicher zu kopieren.<br><br>Um abzubrechen die Nachricht anklicken oder Escape drücken.',
			'copySuccess': {
				'_': '%d Zeilen kopiert',
				'1': '1 Zeile kopiert'
			},
			'pageLength': {
				'-1': 'Zeige alle Zeilen',
				'_': 'Zeige %d Zeilen'
			}
		}
	},
	it: {
		'sEmptyTable': 'Nessun dato presente nella tabella',
		'sInfo': 'Vista da _START_ a _END_ di _TOTAL_ elementi',
		'sInfoEmpty': 'Vista da 0 a 0 di 0 elementi',
		'sInfoFiltered': '(filtrati da _MAX_ elementi totali)',
		'sInfoPostFix': '',
		'sInfoThousands': '.',
		'sLengthMenu': 'Visualizza _MENU_ elementi',
		'sLoadingRecords': 'Caricamento...',
		'sProcessing': 'Elaborazione...',
		'sSearch': 'Cerca:',
		'sZeroRecords': 'La ricerca non ha portato alcun risultato.',
		'oPaginate': {
			'sFirst': 'Inizio',
			'sPrevious': 'Precedente',
			'sNext': 'Successivo',
			'sLast': 'Fine'
		},
		'oAria': {
			'sSortAscending': ': attiva per ordinare la colonna in ordine crescente',
			'sSortDescending': ': attiva per ordinare la colonna in ordine decrescente'
		}
	}
};
