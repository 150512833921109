(function() {
	// globals
	window.EWO.Election = window.EWO.Election || {};

	// module functions
	EWO.MD({
		global: EWO.Election,
		name: 'electionLists',
		containerClass: 'js-electionLists',
		attachToElement: '.js-election-container',
		listTemplate: '/app/election/lists/templates/lists.ejs',
		createOrEditTemplate: '/app/election/lists/templates/listsCreateOrEdit.ejs',
		createOrEditValidationRules: {
			rules: {
				name: {
					required: true
				},
				num: {
					required: true,
					number: true
				},
				shortname: {
					required: true,
					maxlength: 15
				},
				symbolDescription: {
					required: true
				}
			}
		},
		datatablesOptions: {
			pageLength: 50
		},
		apiRoot: EWO.config.apiUrlPrefix + '/lists',
		routingRoot: '/election/:electionId/constituency/:constituencyId/lists'
	});

	//events

	// module startup

	//setup routing
})();
