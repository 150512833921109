(function() {
	// globals
	window.EWO.Users = {};

	//module functions
	EWO.MD({
		global: EWO.Users,
		name: 'users',
		containerClass: 'js-users',
		listTemplate: '/app/users/templates/users.ejs',
		createOrEditTemplate: '/app/users/templates/usersCreateOrEdit.ejs',
		createOrEditValidationRules: {
			rules: {
				username: 'required',
				language: 'required',
				email: 'required email',
				rolesIds: 'required'
				//password: 'required'
			}
		},
		createOrEditAdditionalData: function(id) {
			var d = $.Deferred();
			$.when(
				$.get(EWO.config.apiUrlPrefix + '/roles'),
				$.get(EWO.config.apiUrlPrefix + '/settings?key=PASSWORD_RESET_ADMIN')
			)
			.done(function(roles, setting) {
				d.resolve({
					roles: roles[0].rows,
					setting: setting[0].rows
				});
			})
			.fail(function() {
				d.reject();
			});
			return d;
		},
		createOrEditCallback: function(id) {
			$('#roles').select2();
		},
		beforeSaveCallback: function(detail) {
			if (detail.rolesIds && !$.isArray(detail.rolesIds)) {
				detail.rolesIds = [detail.rolesIds];
			}
			return detail;
		},
		apiRoot: EWO.config.apiUrlPrefix + '/users',
		routingRoot: '/users'
	});

	//events
	$(document).on('click', '.js-users .js-passwordreset', function(ev) {
		ev.preventDefault();
		var ids = [];
		$('.user_checkbox input:checked').each(function() {
			ids.push($(this).attr('data-id'));
		});

		$.ajax({
			url: EWO.config.apiUrlPrefix + '/credentials/reset?ids=' + ids.join(','),
			type: 'POST',
			data: {},
			contentType: 'application/json; charset=UTF-8'
		})
		.done(function(response) {
			PNotify.success('Comunicazioni inviate');
		})
		.fail(function(e) {
			PNotify.error(e.responseText);
		});
	});

	$(document).on('click', '.js-users .js-sendusernames', function(ev) {
		ev.preventDefault();
		var ids = [];
		$('.user_checkbox input:checked').each(function() {
			ids.push($(this).attr('data-id'));
		});

		$.ajax({
			url: EWO.config.apiUrlPrefix + '/credentials/sendusernames?ids=' + ids.join(','),
			type: 'POST',
			data: {},
			contentType: 'application/json; charset=UTF-8'
		})
		.done(function(response) {
			PNotify.success('Comunicazioni inviate');
		});

	});

	$(document).on('change', '.js-users #checkAll', function(ev) {
		var value = $(this).prop('checked');
		$('.user_checkbox input').each(function() {
			$(this).prop('checked', value);
		});
	});

	$(document).on('change', '.js-users #change-password', function() {
		if ($('.js-changepassword').hasClass('d-none')) {
			$('.js-changepassword').removeClass('d-none');
			$('#change-password').prop('checked', true);
		} else {
			$('.js-changepassword').addClass('d-none');
			$('#change-password').prop('checked', false);
		}
		$('.js-users .js-editform').valid();
	});

	//routing
})();
