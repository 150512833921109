(function() {
	// globals
	window.EWO.Election = window.EWO.Election || {};


	// module functions
	EWO.MD({
		global: EWO.Election,
		name: 'electionSections',
		containerClass: 'js-electionSections',
		attachToElement: '.js-election-container',
		listTemplate: '/app/election/sections/templates/sections.ejs',
		createOrEditTemplate: '/app/election/sections/templates/sectionsCreateOrEdit.ejs',
		createOrEditValidationRules: {
			rules: {
				num: {
					required: true,
					number: true
				},
				descDe: 'required',
				descIt: 'required',
				//descRm: 'required',
				municipalityId: 'required',
				maleVoters: {
					required: true,
					number: true,
					min: 0
				},
				femaleVoters: {
					required: true,
					number: true,
					min: 0
				}
			}
		},
		createOrEditAdditionalData: function(id, filter, qs) {
			return $.get(EWO.config.apiUrlPrefix + '/constituencies/municipalitiesforconstituencies?constituencyId=' + filter.constituencyId + '&electionId=' + filter.electionId);
		},
		createOrEditCallback: function() {
			$('#municipalityId').select2();
		},
		listAdditionalData: function(filter) {
			return $.get(EWO.config.apiUrlPrefix + '/constituencies/municipalitiesforconstituencies?constituencyId=' + filter.constituencyId + '&electionId=' + filter.electionId);
		},
		listCallback: function() {
			$('#municipalityId').select2();
		},
		datatablesOptions: {
			pageLength: 100,
			lengthMenu: [[50, 100, 500, -1], [50, 100, 500, "Tutti"] ]
		},
		apiRoot: EWO.config.apiUrlPrefix + '/sections',
		routingRoot: '/election/:electionId/constituency/:constituencyId/sections'
	});

	//events

	// module startup

	//setup routing
})();
