(function() {
	// globals
	window.EWO.Election = window.EWO.Election || {};

	// module functions

	//sections communications
	EWO.MD({
		global: EWO.Election,
		name: 'electionCfgComms',
		containerClass: 'js-electionCfgComms',
		attachToElement: '.js-election-container',
		listTemplate: '/app/election/cfgComms/templates/cfgComms.ejs',
		createOrEditTemplate: '/app/election/cfgComms/templates/cfgCommsCreateOrEdit.ejs',
		createOrEditValidationRules: {
			rules: {
				type: {
					required: true,
					number: true
				},
				turn: {
					required: true,
					number: true
				}
			}
		},
		apiRoot: EWO.config.apiUrlPrefix + '/communicationConfiguration',
		routingRoot: '/election/:electionId/constituency/:constituencyId/cfgComms'
	});

	//events

	// module startup

	//setup routing
})();
