(function() {
	// globals
	window.EWO.Election = window.EWO.Election || {};


	// module functions
	EWO.MD({
		global: EWO.Election,
		name: 'electionStatusPrems',
		containerClass: 'js-electionStatusPrems',
		attachToElement: '.js-election-container',
		listTemplate: '/app/election/statusPrems/templates/statusPrems.ejs',
		createOrEditTemplate: '/app/election/statusPrems/templates/statusPremsCreateOrEdit.ejs',
		createOrEditValidationRules: {
			rules: {
				votes: {
					required: true,
					number: true
				},
				valids: {
					required: true,
					number: true
				},
				invalids: {
					required: true,
					number: true
				},
				blanks: {
					required: true,
					number: true
				}, nulls: {
					required: true,
					number: true,
					equalTo: '#sumOfNullDetails'
				}, contested: {
					required: true,
					number: true
				},
				nulls_Invalid: { required: true, number: true},
				nulls_NullVoteOnly: { required: true, number: true},
				nulls_Withdrawn: { required: true, number: true},
				nulls_NotReturned: { required: true, number: true}
			}
		},
		createOrEditCallback: function() {
			calcSum();
		},
		beforeSaveCallback: function(data) {
			data.values = $('.n-votes').map(function(i, e) {
				return {
					id: Number($(e).attr('data-id')),
					votes: Number($(e).val())
				};
			}).toArray();
			return data;
		},
		apiRoot: EWO.config.apiUrlPrefix + '/survey/statusPremier',
		routingRoot: '/election/:electionId/constituency/:constituencyId/mayorvote'
	});

	var calcSum = function() {
		tot = 0;
		$('.n-votes').each(function() {
			tot += Number(this.value);
		});
		$('#sumPremsVotes').text(tot);
	};

	var calcNullsSum = function() {
		totNulls = 0;
		$('.null-det').each(function() {
			totNulls += Number(this.value);
		});
		if (totNulls >= 0) {
			$('#sumOfNullDetails').val(totNulls);
		} else {
			$('#sumOfNullDetails').val($('nulls').text);
		}
	};

	//events
	$(document).on('change', '.js-electionStatusPrems .n-votes', function(ev) {
		calcSum();
	});

	$(document).on('change', '.js-electionStatusPrems .null-det', function(ev) {
		calcNullsSum();
	});

	// module startup

	//setup routing
})();
