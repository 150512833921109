(function() {
	// globals
	window.EWO.Election = window.EWO.Election || {};


	//handle layout: this draws the sidebar and the main container
	var layout = function(ctx, next) {
		var electionId = ctx.params.electionId;
		var constituencyId = ctx.params.constituencyId;

		var toggleActive = function() {
			$('.js-election-sidebar li.active').removeClass('active');
			$('.js-election-sidebar a').each(function(i, e) {
				if (ctx.path.startsWith(e.pathname)) {
					$(e).parents('li').addClass('active');
				}
			});
		};

		if ($('.js-election-container').length === 0) {
			$.when(
				$.get(EWO.config.apiUrlPrefix + '/sidebar/election/' + electionId + '/constituency/' + constituencyId),
				$.get(EWO.config.apiUrlPrefix + '/constituencies/' + constituencyId),
				ejs.preloadTemplate('/app/election/templates/election.ejs'),
				ejs.preloadTemplate('/app/election/templates/sidebar.ejs'),
				ejs.preloadTemplate('/app/election/templates/sidebarItems.ejs')
			).done(function(sidebarItems, constituency, templateUrl) {
				$('.js-page-container').html(ejs.rr(templateUrl, {
					sidebarItems: sidebarItems[0],
					electionId: electionId,
					constituency: constituency[0]
				}));
				$('.js-page-container').after("<div class='footerPresident' style='display:none'></div>");
				
				toggleActive();
				next();
			});
		} else {
			toggleActive();
			next();
		}
	};


	// module functions
	EWO.Election.renderElectionInstructions = function(ctx) {
		var electionId = ctx.params.electionId;
		var constituencyId = ctx.params.constituencyId;
		$('.js-election-container').html(ejs.rr('/app/election/templates/electionInstructions.ejs', {electionId: electionId, constituencyId: constituencyId}));
	};


	//events
	$(document).on('click', '.js-election-and-sidebar-container #sidebarCollapse', function() {
		$('#sidebar').toggleClass('expanded');
		if ($('#sidebar.expanded').length > 0) {
			$('.js-page-container').css('overflow-x', 'hidden');
		}
	});

	$(document).on('click', '.js-election-and-sidebar-container a.nav-link', function() {
		if ($('#sidebar.expanded').length !== 0) {
			$('#sidebar').removeClass('expanded');
			$('.js-page-container').css('overflow-x', '');
		}
	});

	$(document).on('click', '.js-election-container .js-delete-without-undo', function(ev) {
		ev.preventDefault();
		var el = $(ev.currentTarget);

		var id = el.attr('data-id');
		var deleteApi = el.attr('data-delete-api');

		var notice = PNotify.notice({
			title: i18next.t('Confirm'),
			text: i18next.t('Do you really want to delete?'),
			hide: false,
			modules: {
				Confirm: {
					confirm: true
				},
				Buttons: {
					closer: false,
					sticker: false
				}
			}
		});
		notice.on('pnotify.confirm', function() {
			$.ajax({
				type: 'DELETE',
				url: EWO.config.apiUrlPrefix + '/' + deleteApi + '/' + id
			})
			.done(function() {
				el.parents('tr').remove();
			});
		});
	});

	// module startup

	//setup routing
	page('/election/:electionId/constituency/:constituencyId/*', layout);
	page('/election/:electionId/constituency/:constituencyId/', EWO.Election.renderElectionInstructions);
	// setup edit constituency data for Muni
	page('/election/:electionId/constituencies/edit/:constituencyId', layout, EWO.Election.Constituencies.renderConstituencyCreateOrEdit);
	// setup edit constituency data for Prov.
	page('/election/:electionId/constituencyProv/edit/:constituencyId', layout, EWO.Election.ConstituencyProv.renderConstituencyCreateOrEdit);

})();
