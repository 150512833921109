(function() {
	window.EWO.MD = {};

	EWO.MD = function(options) {
		/* invocation options
		EWO.MD({
			global: EWO.Election,
			name: 'something',
			containerClass: 'js-something',
			attachToElement: '.js-election-container',
			listTemplate: '/app/something/templates/something.ejs',
			createOrEditTemplate: '/app/something/templates/somethingCreateOrEdit.ejs',
			createOrEditValidationRules: {
				rules: {}
			},
			createOrEditAdditionalData: function(id, filter) {return $.Deferred().resolve();},
			createOrEditCallback: function(id, data) {},
			listAdditionalData: function() {return $.Deferred().resolve();},
			listCallback: function() {},
			beforeSaveCallback: function(data) {return data;},
			apiRoot: EWO.config.apiUrlPrefix + '/something',
			apiRootForUpdate: EWO.config.apiUrlPrefix + '/something',
			routingRoot: '/something',
			datatablesOptions: {}
		});
		*/

		//defaults
		options.createOrEditAdditionalData = options.createOrEditAdditionalData || $.Deferred().resolve;
		options.listAdditionalData = options.listAdditionalData || $.Deferred().resolve;
		options.createOrEditCallback = options.createOrEditCallback || function() {};
		options.listCallback = options.listCallback || function() {};
		options.beforeSaveCallback = options.beforeSaveCallback || function(data) {return data;};
		options.afterSaveCallback = options.afterSaveCallback || function(data) {
			//add list filter to query string
			var qs = $.extend({}, Object.fromEntries(moduleFilter), Object.fromEntries(new URLSearchParams(data.redirectParameters)));
			qs = new URLSearchParams(qs).toString();

			//if we have /edit/34234 then we remove two elements from the url,
			//it it is /add we remove just one element
			page(document.location.pathname.split('/').slice(0, (data.detail.id ? -2 : -1)).join('/') + (qs ? '?' + qs : ''));
		};
		options.afterSaveErrorCallback = options.afterSaveErrorCallback || function(data) {};
		options.attachToElement = options.attachToElement || '.js-page-container';
		options.createOrEditValidationRules = options.createOrEditValidationRules || {rules: {}};
		options.datatablesOptions = options.datatablesOptions || {};

		var moduleFilter; //remember filter after save

		// module functions
		var refreshUrl = function(dtoptions) {
			var search = document.location.search;
			if (!$.isEmptyObject(dtoptions)) {
				var urlQueryString = new URLSearchParams(search);
				if (urlQueryString.has('dt')) {
					urlQueryString.delete('dt');
					var t = urlQueryString.toString();
					if (t) {
						search = '?' + t;
					} else {
						search = '';
					}
				}
				search = (search ? search + '&' : '?') + 'dt=' + encodeURIComponent(JSON.stringify(dtoptions));
			} else {
				search = (search ? search : '');
			}
			return document.location.pathname + search;
		};

		var refresh = function(dtoptions) {
			page(refreshUrl(dtoptions));
		};

		var getDatatableOptions = function(datatable) {
			var dtoptions = {};
			if (datatable.length > 0) {
				dtoptions.order = datatable.DataTable().order();
				dtoptions.page = datatable.DataTable().page();
				dtoptions.pageLength = datatable.DataTable().page.len();
				dtoptions.lengthMenu = datatable.DataTable().lengthMenu;
			}
			return dtoptions;
		};

		options.global['render' + options.name] = function(ctx) {
			var urlQueryString = new URLSearchParams(ctx.querystring);
			moduleFilter = urlQueryString;
			//check if there are datatables options in the query string
			var dtoptions = options.datatablesOptions;
			if (urlQueryString.has('dt')) {
				dtoptions = $.extend({}, dtoptions, JSON.parse(decodeURIComponent(urlQueryString.get('dt'))));
				urlQueryString.delete('dt');
			}

			var filter = Object.fromEntries(urlQueryString);
			$.extend(filter, ctx.params);

			$.when(
				ejs.preloadTemplate(options.listTemplate),
				$.get(options.apiRoot, filter),
				options.listAdditionalData(filter)
			)
			.done(function(templateUrl, list, additionalData) {
				$(options.attachToElement).html(ejs.rr(templateUrl, {filter: filter, list: list[0], data: additionalData}));
				var dt = $(options.attachToElement + ' .table');
				dt.DataTable(dtoptions);
				if (!$.isEmptyObject(dtoptions)) {
					if (dt.DataTable().page(dtoptions.page)) {
						dt.DataTable().page(dtoptions.page).draw('page');
					}
				}

				//listen for dt events, so paging/sort/page are persisted in the query string
				var setUrl = function() {
					var dtoptions = getDatatableOptions(dt);
					var newurl = refreshUrl(dtoptions);
					history.pushState({path: newurl}, '', newurl);
				};
				dt.DataTable().on('order.dt', function(e, settings, ordArr) {
					setUrl();
				});
				dt.DataTable().on('length.dt', function(e, settings, ordArr) {
					setUrl();
				});
				dt.DataTable().on('page.dt', function(e, settings, ordArr) {
					setUrl();
				});

				//calback
				options.listCallback();
				presidentContactFooter();
			});
		};

		options.global['render' + options.name + 'CreateOrEdit'] = function(ctx) {
			var id = ctx.params.id;
			var filter = $.extend({}, ctx.params);
			var urlQueryString = new URLSearchParams(ctx.querystring);
			var querystring = Object.fromEntries(urlQueryString);

			var render = function(detail) {
				$.when(
					ejs.preloadTemplate(options.createOrEditTemplate),
					options.createOrEditAdditionalData(id, filter, querystring, detail)
				)
				.done(function(templateUrl, additionalData) {
					$(options.attachToElement).html(ejs.rr(templateUrl, {detail: detail, data: additionalData, filter: filter}));

					//validation rules
					$('.js-editform').validate(options.createOrEditValidationRules);

					//calback
					options.createOrEditCallback(id, {detail: detail, data: additionalData, filter: filter});
					presidentContactFooter();
				});
			};

			if (id) {
				$.get((options.apiRootForUpdate ? options.apiRootForUpdate : options.apiRoot) + '/' + id, querystring)
				.done(function(detail) {
					render(detail);
				});
			} else {
				render({});
			}
		};

		
		presidentContactFooter = function(){
			$(document).ready(function(){
				if($('[name="sectionId"').length > 0){
					var sectionId = $('[name="sectionId"').val();
					if(sectionId > 0)
					{
						if($('[name="constituencyId"').length > 0){
							var constituencyId = $('[name="constituencyId"').val();

							if(constituencyId > 0)
							{
								$.get(options.apiRoot + '/sectionpresident/' + constituencyId + '/' + sectionId)
								.done(function(president) {
									if(president != undefined)
									{
										$('.footerPresident').after(
											"<div class='footerPresident' style='display:none'><div class='container'>"
											+"<div class='float-right'><b>"+ i18next.t('Section President') 
											+": </b>"+ president.firstname + " " + president.familyname + " - <b>" + i18next.t('Phone') 
											+" : </b>"+ "<a href='" + president.phone + "'>" + president.phone + "</a>" 
											+ "<button type='button' data-phone='"+ president.phone
											+"' class='btn btn-outline-primary js-contactPresident ml-3'><span class='fa fa-phone' aria-hidden='true'></span> "
											+ i18next.t('Contact') + "</button>"
											+"</div>"
											+"</div></div>");
										$(".footerPresident").show();
									}
								});
							}
						}
					}
				}
			});
		}
		

		// module events
		$(document).on('submit', '.' + options.containerClass + ' .js-filterform', function(ev) {
			var el = $(ev.currentTarget);
			ev.preventDefault();
			var filter = el.serialize();
			page(document.location.pathname + (filter ? '?' + filter : ''));
		});

		$(document).on('submit', '.' + options.containerClass + ' .js-editform', function(ev) {
			var detailForm = $(ev.currentTarget);
			ev.preventDefault();
			var redirectParameters = detailForm.attr('data-redirect-parameters');

			var detail = EWO.Utils.formToObject(detailForm);
			detail = options.beforeSaveCallback(detail);

			$.ajax({
				type: (detail.id ? 'PUT' : 'POST'),
				url: (options.apiRootForUpdate ? options.apiRootForUpdate : options.apiRoot) + (detail.id ? '/' + detail.id : ''),
				data: JSON.stringify(detail),
				contentType: 'application/json; charset=UTF-8'
			})
			.done(function(response) {
				options.afterSaveCallback({response: response, detail: detail, redirectParameters: redirectParameters});
			})
			.fail(function(response) {
				options.afterSaveErrorCallback({response: response, detail: detail, redirectParameters: redirectParameters});
			});
		});

		$(document).on('click', '.' + options.containerClass + ' .js-delete', function(ev) {
			var el = $(ev.currentTarget);
			ev.preventDefault();

			var id = el.attr('data-id');

			$.ajax({
				type: 'DELETE',
				url: (options.apiRootForUpdate ? options.apiRootForUpdate : options.apiRoot) + '/' + id
			})
			.done(function() {
				el.parents('tr').remove();
				var undoNotice = i18next.t('Entry deleted. Do you want to <a href="javascript:///" class="js-undo" data-id="{{id}}">undo</a>?', {id: id});
				var notification = PNotify.success({
					text: undoNotice,
					textTrusted: true,
					addClass: options.containerClass + ' js-undo-' + id
				});
				$('.' + options.containerClass + '.js-undo-' + id).data('pnotify', notification);
			});
		});


		$(document).on('click', '.' + options.containerClass + ' .js-publish', function(ev) {
			var el = $(ev.currentTarget);
			ev.preventDefault();

			var id = el.attr('data-id');
			var datatable = $('.' + options.containerClass + ' .dataTable');
			var dtoptions = getDatatableOptions(datatable);

			$.ajax({
				type: 'POST',
				url: (options.apiRootForUpdate ? options.apiRootForUpdate : options.apiRoot) + '/publish/' + id
			})
			.done(function() {
				refresh(dtoptions);
			});
		});

		$(document).on('click', '.' + options.containerClass + ' .js-unpublish', function(ev) {
			var el = $(ev.currentTarget);
			ev.preventDefault();

			var id = el.attr('data-id');
			var datatable = $('.' + options.containerClass + ' .dataTable');
			var dtoptions = getDatatableOptions(datatable);

			$.ajax({
				type: 'POST',
				url: (options.apiRootForUpdate ? options.apiRootForUpdate : options.apiRoot) + '/unpublish/' + id
			})
			.done(function() {
				refresh(dtoptions);
			});
		});


		$(document).on('click', '.' + options.containerClass + ' .js-undo', function(ev) {
			var el = $(ev.currentTarget);
			ev.preventDefault();

			var id = el.attr('data-id');
			var datatable = $('.' + options.containerClass + ' .dataTable');
			var dtoptions = getDatatableOptions(datatable);

			$.ajax({
				type: 'POST',
				url: (options.apiRootForUpdate ? options.apiRootForUpdate : options.apiRoot) + '/undodelete/' + id
			})
			.done(function() {
				refresh(dtoptions);
				$('.' + options.containerClass + '.js-undo-' + id).data('pnotify').remove();
			});
		});

		$(document).on('dblclick', '.' + options.containerClass + ' .table tbody tr', function(el) {
			var row = $(el.currentTarget);
			if (row.attr('data-id') != undefined) {
				{var redirectParameters = row.attr('data-redirect-parameters');}
				page(document.location.pathname + '/edit/' + row.attr('data-id') + (redirectParameters ? '?' + redirectParameters : ''));
			}
		});

		// module startup




		//setup routing
		page(options.routingRoot, options.global['render' + options.name]);
		page(options.routingRoot + '/edit/:id', options.global['render' + options.name + 'CreateOrEdit']);
		page(options.routingRoot + '/add', options.global['render' + options.name + 'CreateOrEdit']);
	};

})();
