(function() {
	// globals
	window.EWO.Election = window.EWO.Election || {};

	// module functions

	//coalitions
	EWO.MD({
		global: EWO.Election,
		name: 'electionCoalitions',
		containerClass: 'js-electionCoalitions',
		attachToElement: '.js-election-container',
		listTemplate: '/app/election/coalitions/templates/coalitions.ejs',
		createOrEditTemplate: '/app/election/coalitions/templates/coalitionsEdit.ejs',
		createOrEditValidationRules: {
			rules: {
				mayorId: 'required'
			}
		},
		createOrEditAdditionalData: function(id, filter) {
			var d = $.Deferred();
			$.when(
				$.get(EWO.config.apiUrlPrefix + '/mayors?electionId=' + filter.electionId + '&constituencyId=' + filter.constituencyId + '&onlyMayor=true')
			)
			.done(function(mayors) {
				d.resolve({
					mayors: mayors.rows
				});
			})
			.fail(function() {
				d.reject();
			});
			return d;
		},
		apiRoot: EWO.config.apiUrlPrefix + '/mayorlists',
		routingRoot: '/election/:electionId/constituency/:constituencyId/coalitions'
	});

	//events

	// module startup

	//setup routing
})();
