(function() {
	// globals
	window.EWO.Election = window.EWO.Election || {};


	// module functions
	EWO.MD({
		global: EWO.Election,
		name: 'electionUserBoundaries',
		containerClass: 'js-electionUserBoundaries',
		attachToElement: '.js-election-container',
		listTemplate: '/app/election/boundaries/templates/boundaries.ejs',
		createOrEditTemplate: '/app/election/boundaries/templates/boundariesCreateOrEdit.ejs',
		createOrEditValidationRules: {
			rules: {
				userId: 'required',
				municipalityId: 'required'
			}
		},
		createOrEditAdditionalData: function(id, filter) {
			delete filter.id;
			var d = $.Deferred();
			$.when(
				$.get(EWO.config.apiUrlPrefix + '/users'),
				$.get(EWO.config.apiUrlPrefix + '/constituencies/municipalitiesforconstituencies', filter)
			)
			.done(function(u, m) {
				d.resolve({
					users: u[0].rows.map(function(e) {return {id: e.id, desc: e.username};}),
					municipalities: m[0].rows
				});
			})
			.fail(function() {
				d.reject();
			});
			return d;
		},
		createOrEditCallback: function(id) {
			$('#userId').select2();
			$('#municipalityId').select2();
		},
		datatablesOptions: {
			pageLength: 500,
			lengthMenu: [[50, 100, 500, -1], [50, 100, 500, "Tutti"] ]
		},
		apiRoot: EWO.config.apiUrlPrefix + '/boundaries',
		routingRoot: '/election/:electionId/constituency/:constituencyId/boundaries'
	});

	//events

	//module startup

	//setup routing
})();
