(function() {
	// globals
	window.EWO.Settings = {};

	// Study titles
	EWO.MD({
		global: EWO.Settings,
		name: 'academicStudyTitles',
		containerClass: 'js-academicstudytitles',
		listTemplate: '/app/settings/templates/academicStudyTitles.ejs',
		createOrEditTemplate: '/app/settings/templates/academicStudyTitlesCreateOrEdit.ejs',
		createOrEditValidationRules: {
			rules: {
				descDe: 'required',
				descIt: 'required'
			}
		},
		apiRoot: EWO.config.apiUrlPrefix + '/titles',
		routingRoot: '/settings/academicstudytitles'
	});


	// Districts
	EWO.MD({
		global: EWO.Settings,
		name: 'districts',
		containerClass: 'js-districts',
		listTemplate: '/app/settings/templates/districts.ejs',
		createOrEditTemplate: '/app/settings/templates/districtsCreateOrEdit.ejs',
		createOrEditValidationRules: {
			rules: {
				num: 'required number',
				descDe: 'required',
				descIt: 'required',
				descRm: 'required'
			}
		},
		apiRoot: EWO.config.apiUrlPrefix + '/districts',
		routingRoot: '/settings/districts'
	});


	// Configuration Settings
	EWO.MD({
		global: EWO.Settings,
		name: 'configurationSettings',
		containerClass: 'js-configurationSettings',
		listTemplate: '/app/settings/templates/configurationSettings.ejs',
		createOrEditTemplate: '/app/settings/templates/configurationSettingsCreateOrEdit.ejs',
		createOrEditValidationRules: {
			rules: {
				key: 'required'
			}
		},
		apiRoot: EWO.config.apiUrlPrefix + '/settings',
		routingRoot: '/settings/configurationSettings'
	});


	// Municipalites
	EWO.MD({
		global: EWO.Settings,
		name: 'municipalities',
		containerClass: 'js-municipalities',
		listTemplate: '/app/settings/templates/municipalities.ejs',
		createOrEditTemplate: '/app/settings/templates/municipalitiesCreateOrEdit.ejs',
		createOrEditValidationRules: {
			rules: {
				num: 'required number',
				descDe: 'required',
				descIt: 'required',
				descRm: 'required',
				prov: {
					required: true,
					maxlength: 2
				},
				districtId: 'required',
				flags: 'required'
			}
		},
		createOrEditAdditionalData: function(id) {
			return $.get(EWO.config.apiUrlPrefix + '/districts');
		},
		createOrEditCallback: function(id) {
			$('#districtId').select2();
		},
		listAdditionalData: function(filter) {
			return $.get(EWO.config.apiUrlPrefix + '/types/provinces');
		},
		apiRoot: EWO.config.apiUrlPrefix + '/municipalities',
		routingRoot: '/settings/municipalities'
	});
})();
