(function() {
	// globals
	EWO.Info = {};

	// utility functions

	// app functions
	EWO.Info.rederDocumentation = function() {
		$.get('/version.json')
		.done(function(version) {
			$('.js-page-container').html(ejs.rr('/app/info/documentation/templates/documentation.ejs', {version: version}));
		});
	};

	// events

	// startup

	//routes
	page('/info/documentation', EWO.Info.rederDocumentation);
})();
