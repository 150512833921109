(function() {
	// globals
	window.EWO.Election = window.EWO.Election || {};
	EWO.Election.Monitor = {};
	EWO.Election.Monitor.mouseOverPaused = false;
	EWO.Election.Monitor.mouseOverPausedTimer = false;
	EWO.Election.Monitor.rotationTimer = false;

	// utility functions

	// app functions
	EWO.Election.Monitor.rederMonitor = function(ctx) {
		var urlQueryString = new URLSearchParams(ctx.querystring);
		var filter = Object.fromEntries(urlQueryString);

		$.when(
			$.get(EWO.config.apiUrlPrefix + '/monitor?electionId=' + ctx.params.electionId),
			ejs.preloadTemplate('/app/election/monitor/templates/monitor.ejs'),
			ejs.preloadTemplate('/app/election/monitor/templates/monitorMapAndTable.ejs'),
			ejs.preloadTemplate('/app/election/monitor/templates/monitorTable.ejs'),
			ejs.preloadTemplate('/app/election/monitor/templates/municipalityMapDetail.ejs'),
			ejs.preloadTemplate('/app/election/monitor/templates/phaseStatus.ejs')
		).done(function(munis) {
			var municipalities = munis[0].rows;
			$('.js-page-container').html(ejs.rr('/app/election/monitor/templates/monitor.ejs', {
				municipalities: municipalities,
				electionId: ctx.params.electionId,
				filter: filter || {phase: 'turn1_1', interval: 3},
				autorefresh: filter.autorefresh || false
			}));
			$('.js-map-element').each(function(i, element) {
				el = $(element);
				var municipalityIstatCode = el.attr('id');
				var municipality = EWO.Election.Monitor.getByIstatCode(municipalities, municipalityIstatCode);
				if (municipality) {
					el.data('municipality', municipality);
					el.addClass(EWO.Election.Monitor.getMunicipalityClass($('#phase').val(), municipality));
				} else {
					el.addClass('st-neutral');
				}
			});
			if (filter.municipalityId) {
				$('#municipalityId').val(filter.municipalityId);
				EWO.Election.Monitor.selectElement($('.js-map-element[id="' + filter.municipalityId + '"]'), true);
			}
		});
	};

	EWO.Election.Monitor.getByIstatCode = function(municipalities, municipalityIstatCode) {
		return $.grep(municipalities, function(e) {
			return e.municipalityIstatCode == municipalityIstatCode;
		})[0];
	};

	EWO.Election.Monitor.updateDetail = function(municipality) {
		$('.js-map-municipality-detail').html(ejs.rr('/app/election/monitor/templates/municipalityMapDetail.ejs', {
			municipality: municipality,
			electionId: $('.js-election-monitor').attr('data-election-id')
		}));
		$('.js-map-municipality-detail').attr('style', '');
	};

	EWO.Election.Monitor.getMunicipalityClass = function(selectedPhase, municipality) {
		var phase = municipality[selectedPhase];
		if (phase.failed > 0) {
			return 'st-errors';
		}
		if (phase.saved > 0) {
			return 'st-has-data';
		}
		if (phase.empty > 0) {
			return 'st-no-data';
		}
		if (phase.published > 0) {
			return 'st-published';
		}

		return 'st-neutral';
	};

	EWO.Election.Monitor.selectElement = function(el, force) {
		if (el.hasClass('js-map-element')) {
			var municipality = el.data('municipality');
			if (municipality && (municipality.municipalityIstatCode != $('#municipalityId').val() || force)) {
				$('.js-map-element').attr('style', '');
				el.attr('style', 'stroke: black; stroke-width: 800;');
				EWO.Election.Monitor.updateDetail(municipality);

				//update table
				$('.js-monitor-municipality-row').removeClass('table-warning');
				$('.js-monitor-municipality-row-' + municipality.municipalityIstatCode).addClass('table-warning');

				//update path and muniId in form
				$('#municipalityId').val(municipality.municipalityIstatCode);

				var search = new URLSearchParams(document.location.search);
				var newurl = document.location.pathname;
				search.set('municipalityId', municipality.municipalityIstatCode);
				newurl = newurl + '?' + search.toString();
				history.replaceState({path: newurl}, '', newurl);
			}
		}
	};

	EWO.Election.Monitor.playRotateStop = function() {
		$('.js-play-rotate').css('color', '').removeClass('blink-me');
		clearTimeout(EWO.Election.Monitor.rotationTimer);
		EWO.Election.Monitor.rotationTimer = false;
	};

	EWO.Election.Monitor.playRotateStart = function() {
		if ($('.js-map-element').not('.st-published, .st-neutral').length === 0) {
			PNotify.success(i18next.t('There are no municiplities with errors to loop on!'));
			return;
		}
		$('.js-play-rotate').css('color', '#dc3545').addClass('blink-me');
		var triggerNext = function(selected) {
			if ($('.js-election-monitor').length === 0) { //we are not on this page anymore
				EWO.Election.Monitor.playRotateStop();
				return;
			}
			var selected = selected || $('.js-monitor-municipality-row.table-warning').first();
			var select = undefined;
			if (!selected || selected.length === 0) {
				selected = $('.js-monitor-municipality-row').first();
				select = selected;
			}
			var select = select || selected.next('tr');

			if (select.length === 0) { //there was no tr after this, so return to the first one
				select = $('.js-monitor-municipality-row').first();
			}

			//loop only on anomalies
			var municipalityId = select.attr('data-municipality-id');
			var municipality = $('.js-map-element[id="' + municipalityId + '"]').data('municipality');
			if (municipality) {
				var municipalityClass = EWO.Election.Monitor.getMunicipalityClass($('#phase').val(), municipality);
				if (municipalityClass !== 'st-published' && municipalityClass !== 'st-neutral') {
					select.trigger('click');
				} else {
					triggerNext(select);
				}
			} else {
				triggerNext(select);
			}
		};
		triggerNext();
		EWO.Election.Monitor.rotationTimer = setInterval(triggerNext, $('#interval').val() * 1000);
	};

	// events
	$(document).on('mousemove', '.js-election-monitor .js-status-map', function(ev) {
		var el = $(ev.target);

		if (!EWO.Election.Monitor.mouseOverPaused && !EWO.Election.Monitor.rotationTimer) {
			EWO.Election.Monitor.selectElement(el);
		}
	});

	$(document).on('click', '.js-election-monitor .js-monitor-municipality-row a', function(ev) {
		ev.stopPropagation(); //don't change municipality when clicking on a link
	});

	$(document).on('click', '.js-election-monitor #autorefresh', function(ev) {
		var r = function() {
			setTimeout(function() {
				if (document.location.pathname.endsWith('/monitor') && $('#autorefresh').prop('checked')) {
					EWO.Election.Monitor.playRotateStop();
					page(document.location);
					r();
				}
			}, 15000);
		};
		r();

		var filter = $('.js-filterform').serialize();
		page(document.location.pathname + (filter ? '?' + filter + '&autorefresh=' + $('#autorefresh').prop('checked') : '?autorefresh=' + $('#autorefresh').prop('checked')));
	});

	$(document).on('click', '.js-election-monitor .js-status-map', function(ev) {
		ev.preventDefault();

		var el = $(ev.target);
		EWO.Election.Monitor.selectElement(el);

		EWO.Election.Monitor.playRotateStop();

		if (EWO.Election.Monitor.mouseOverPausedTimer) {
			clearTimeout(EWO.Election.Monitor.mouseOverPausedTimer);
			EWO.Election.Monitor.mouseOverPausedTimer = false;
		}

		EWO.Election.Monitor.mouseOverPaused = true;
		EWO.Election.Monitor.mouseOverPausedTimer = setTimeout(function() {
			EWO.Election.Monitor.mouseOverPaused = false;
		}, 2000);
	});

	$(document).on('change', '.js-election-monitor #phase', function(ev) {
		ev.preventDefault();

		EWO.Election.Monitor.playRotateStop();

		var filter = $('.js-filterform').serialize();
		page(document.location.pathname + (filter ? '?' + filter : ''));
	});

	$(document).on('submit', '.js-election-monitor .js-filterform', function(ev) {
		ev.preventDefault();

		EWO.Election.Monitor.playRotateStop();

		var filter = $('.js-filterform').serialize();
		page(document.location.pathname + (filter ? '?' + filter : ''));
	});

	$(document).on('click', '.js-election-monitor .js-monitor-municipality-row', function(ev) {
		var id = $(ev.currentTarget).attr('data-municipality-id');
		var pathElement = $('path[id="' + id + '"]');
		EWO.Election.Monitor.selectElement(pathElement);
	});

	$(document).on('click', '.js-election-monitor .js-play-rotate', function(ev) {
		if (EWO.Election.Monitor.rotationTimer) {
			EWO.Election.Monitor.playRotateStop();
		} else {
			$('#autorefresh').prop('checked', false);
			EWO.Election.Monitor.playRotateStart();
		}
	});

	// startup

	//routes
	page('/election/:electionId/monitor', EWO.Election.Monitor.rederMonitor);
})();
