(function() {
	// globals
	window.EWO.Elections = {};

	EWO.MD({
		global: EWO.Elections,
		name: 'elections',
		containerClass: 'js-elections',
		listTemplate: '/app/elections/templates/elections.ejs',
		createOrEditTemplate: '/app/elections/templates/electionsCreateOrEdit.ejs',
		createOrEditValidationRules: {
			rules: {
				electionTypeId: 'required',
				descDe: 'required',
				descIt: 'required',
				statusId: 'required',
				electionDate: 'required date',
				runoffDate: 'required date'
			}
		},
		createOrEditAdditionalData: function(id) {
			var d = $.Deferred();
			$.when(
				$.get(EWO.config.apiUrlPrefix + '/types/elections'),
				$.get(EWO.config.apiUrlPrefix + '/statuses')
			)
			.done(function(t, s) {
				d.resolve({
					types: t[0].rows,
					statuses: s[0].rows
				});
			})
			.fail(function() {
				d.reject();
			});
			return d;
		},
		createOrEditCallback: function(id) {
			$('#electionTypeId').select2();
			$('#statusId').select2();
			$('#electionDate').datepicker();
			$('#runoffDate').datepicker();
		},
		listAdditionalData: function(filter) {
			return $.get(EWO.config.apiUrlPrefix + '/types/elections');
		},
		beforeSaveCallback: function(data) {
			//fix dates
			data.electionDate = moment(data.electionDate, 'L').format('YYYY-MM-DD');
			data.runoffDate = moment(data.runoffDate, 'L').format('YYYY-MM-DD');
			return data;
		},
		apiRoot: EWO.config.apiUrlPrefix + '/elections',
		routingRoot: '/elections'
	});

	//events
	$(document).on('click', '.js-elections .js-current-election', function(ev) {
		var el = $(ev.currentTarget);
		ev.preventDefault();
		var id = el.attr('data-id');

		$.ajax({
			type: 'POST',
			url: EWO.config.apiUrlPrefix + '/elections/setascurrent/' + id
		})
		.done(function() {
			PNotify.success(i18next.t('The current election has been set'));
			page('/elections');
		});
	});

	$(document).on('click', '.js-elections .js-compare-election', function(ev) {
		var el = $(ev.currentTarget);
		ev.preventDefault();
		var id = el.attr('data-id');

		$.ajax({
			type: 'POST',
			url: EWO.config.apiUrlPrefix + '/elections/setascompare/' + id
		})
		.done(function() {
			PNotify.success(i18next.t('The election has been set for comparison'));
			page('/elections');
		});
	});


	$(document).on('click', '.js-elections .js-template-election', function(ev) {
		var el = $(ev.currentTarget);
		ev.preventDefault();
		var id = el.attr('data-id');

		$.ajax({
			type: 'POST',
			url: EWO.config.apiUrlPrefix + '/elections/copytemplate/' + id
		})
		.done(function() {
			PNotify.success(i18next.t('Election template copied'));
			page('/elections');
		});
	});

	$(document).on('dblclick', '.js-elections .table tbody tr', function(el) {
		var row = $(el.currentTarget);
		if (row.attr('data-manage-id') != undefined) {
			{var redirectParameters = row.attr('data-redirect-parameters');}
			if(row.attr('data-type-id') == 16)
			{
			page('/election/' + row.attr('data-manage-id') + '/constituencies' + (redirectParameters ? '?' + redirectParameters : ''));
			}
			else if(row.attr('data-type-id') == 32)
			{
				page('/election/' + row.attr('data-manage-id') + '/constituencyProv' + (redirectParameters ? '?' + redirectParameters : ''));
			}

		}
	});

})();
