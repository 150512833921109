(function() {
	// globals
	window.EWO.Election = window.EWO.Election || {};

	// module functions

	//coalitions
	EWO.MD({
		global: EWO.Election,
		name: 'electionCoalitionsRunoff',
		containerClass: 'js-electionCoalitionsRunoff',
		attachToElement: '.js-election-container',
		listTemplate: '/app/election/coalitionsRunoff/templates/coalitionsRunoff.ejs',
		createOrEditTemplate: '/app/election/coalitionsRunoff/templates/coalitionsRunoffEdit.ejs',
		// createOrEditValidationRules: {
		// 	rules: {
		// 		mayorId: 'required'
		// 	}
		// },
		createOrEditAdditionalData: function(id, filter) {
			var d = $.Deferred();
			$.when(
				$.get(EWO.config.apiUrlPrefix + '/mayors/runoff?electionId=' + filter.electionId + '&constituencyId=' + filter.constituencyId + '&onlyMayor=true')
			)
			.done(function(mayors) {
				d.resolve({
					mayors: mayors.rows
				});
			})
			.fail(function() {
				d.reject();
			});
			return d;
		},
		apiRoot: EWO.config.apiUrlPrefix + '/mayorlists/runoff',
		routingRoot: '/election/:electionId/constituency/:constituencyId/coalitionsRunoff'
	});

	//events

	// module startup

	//setup routing
})();
