(function() {
	// globals
	window.EWO.Credentials = {};

	token = '';

	// module functions
	EWO.Credentials.renderCredentials = function(ctx) {
		var urlQueryString = new URLSearchParams(ctx.querystring);
		token = urlQueryString.get('token');

		$.when(
			ejs.preloadTemplate('/app/credentials/templates/credentials.ejs'),
			$.get(EWO.config.apiUrlPrefix + '/credentials?token=' + token)
		)
		.done(function(templateUrl, user) {
			$('.js-main-content').html(ejs.rr(templateUrl, {user: user[0]}));
			$('#form-credentials').validate({
				rules: {
					'password': {
						required: true,
						passwordstrenght: true
					},
					'checkpassword': {
						required: true,
						equalTo: '#password'
					}
				}
			});
		})
		.fail(function(e) {
			page('/login');
		});
	};

	EWO.Credentials.renderPasswordRequest = function(ctx) {
		ejs.preloadTemplate('/app/credentials/templates/requestpassword.ejs')
		.done(function(templateUrl) {
			$('.js-main-content').html(ejs.rr(templateUrl));
			$('#form-requestpsw').validate({
				rules: {
					'username': {
						required: true
					}
				}
			});
		});
	};

	// module events
	$(document).on('onblur', '.js-credentials #password', function(ev) {
		var field = $('#password');
		field.value = field.value.trim();
	});

	$(document).on('submit', '.js-credentials #form-credentials', function(ev) {
		ev.preventDefault();
		var passwordFld = $('[name="password"]').val();
		//var token = urlQueryString.get('token');

		// disable button
		$('button[type="submit"]', '#form-credentials').prop('disabled', true);
		// add spinner to button
		$('button[type="submit"]', '#form-credentials').html(
			'<span class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span> Inviando...'
		);

		var dataT = JSON.stringify({'token': token, 'password': passwordFld});

		$.ajax({
			url: EWO.config.apiUrlPrefix + '/credentials/editpassword',
			method: 'POST',
			data: dataT,
			contentType: 'application/json; charset=UTF-8',
			handled: true
		})
		.done(function(response) {
			PNotify.success('Password succesfully changed!');
			page('/login');
		})
		.fail(function(e) {
			PNotify.error(e.responseText);
			page('/');
		});
	});

	$(document).on('submit', '.js-requestpsw #form-requestpsw', function(ev) {
		ev.preventDefault();
		var username = $('[name="username"]').val().replace('.', '');
		// disable button
		$('button[type="submit"]', '#form-requestpsw').prop('disabled', true);
		// add spinner to button
		$('button[type="submit"]', '#form-requestpsw').html(
			'<span class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span> Inviando...'
		);

		$.ajax({
			url: EWO.config.apiUrlPrefix + '/credentials/requestpassword?username=' + username,
			method: 'POST',
			contentType: 'application/json; charset=UTF-8',
			handled: true
		})
		.done(function(response) {
			PNotify.success('Richiesta inviata correttamente!');
			page('/login');
		})
		.fail(function(e) {
			PNotify.error(e.responseText);
		});
	});


	// module startup
	//setup routing
	page('/credentials', EWO.Credentials.renderCredentials);
	page('/credentials/requestpassword', EWO.Credentials.renderPasswordRequest);
})();
