(function() {
	// globals
	window.EWO.Election = window.EWO.Election || {};


	// module functions
	EWO.Election.renderListsAndCandidates = function(ctx) {
		var urlQueryString = new URLSearchParams(ctx.querystring);
		var filter = Object.fromEntries(urlQueryString);
		$.extend(filter, ctx.params);

		$.when(
			ejs.preloadTemplate('/app/election/listsAndCandidates/templates/listsAndCandidates.ejs'),
			$.get(EWO.config.apiUrlPrefix + '/listsAndCandidates', filter),
			$.get(EWO.config.apiUrlPrefix + '/constituencies/municipalitiesforconstituencies', filter),
			$.get(EWO.config.apiUrlPrefix + '/elections/' + filter.electionId)
		)
		.done(function(templateUrl, list, municipalities, election) {
			$('.js-election-container').html(ejs.rr(templateUrl, {list: list[0], data: municipalities, election: election[0], filter: filter}));
		});
	};

	//events
	$(document).on('submit', '.js-electionStatisticsListsAndCandidates .js-filterform', function(ev) {
		var el = $(ev.currentTarget);
		ev.preventDefault();
		var filter = el.serialize();
		page(document.location.pathname + (filter ? '?' + filter : ''));
	});

	$(document).on('click', '.js-electionStatisticsListsAndCandidates .js-csv-export', function() {
		var filterForm = $('.js-electionStatisticsListsAndCandidates .js-filterform');
		var expFilter = filterForm.serialize();
		window.location.href = EWO.config.apiUrlPrefix + '/listsAndCandidates/csvexport?' + expFilter;
	});

	$(document).on('click', '.js-electionStatisticsListsAndCandidates .js-export', function(ev) {
		var el = $(ev.currentTarget);
		var type = $(el).data('type');
		var constituencyId = $('input[name="constituencyId"]').val();
		window.location.href = EWO.config.apiUrlPrefix + '/listsAndCandidates/exportdoc?constituencyId=' + constituencyId + '&format=' + type;
	});

	$(document).on('click', '.js-electionStatisticsListsAndCandidates .js-verbal', function(ev) {
		var el = $(ev.currentTarget);
		var type = $(el).data('type');
		var constituencyId = $('input[name="constituencyId"]').val();
		window.location.href = EWO.config.apiUrlPrefix + '/listsAndCandidates/exportverbal?constituencyId=' + constituencyId + '&format=' + type;
	});


	// module startup

	//setup routing
	page('/election/:electionId/constituency/:constituencyId/expAnags', window.EWO.Election.renderListsAndCandidates);
})();
