// env dependent config goes here

(function() {
	if (typeof(window.EWO) === 'undefined') {
		window.EWO = {};
	}

	var env = 'production';
	if (location.host.startsWith('dev-') || location.host.startsWith('localhost') || location.host.startsWith('127.0.0.1')) {
		env = 'development';
	} else if (location.host.startsWith('test-')) {
		env = 'test';
	}

	EWO.config = {
		apiUrlPrefix: '/api/v1',
		environment: env
	};

})();
