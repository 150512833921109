(function() {
	// globals
	EWO.Info = {};

	// utility functions

	// app functions
	EWO.Info.rederUseractivities = function(ctx) {
		var urlQueryString = new URLSearchParams(ctx.querystring);
		var filter = Object.fromEntries(urlQueryString);
		filter = $.extend({minutes: '10'}, filter);

		$.when(
			ejs.preloadTemplate('/app/info/useractivities/templates/useractivities.ejs'),
			$.get(EWO.config.apiUrlPrefix + '/users/logged', filter),
			$.get(EWO.config.apiUrlPrefix + '/municipalities')
		)
		.done(function(templateUrl, ua, municipalities) {
			$('.js-page-container').html(ejs.rr(templateUrl, {
				list: ua[0],
				data: {
					municipalities: municipalities[0].rows
				},
				filter: filter
			}));
			$('#municipalityId').select2();
		});
	};

	// events
	$(document).on('submit', '.js-useractivities .js-filterform', function(ev) {
		var el = $(ev.currentTarget);
		ev.preventDefault();
		var filter = el.serialize();
		page(document.location.pathname + (filter ? '?' + filter : ''));
	});

	// startup

	//routes
	page('/info/useractivities', EWO.Info.rederUseractivities);
})();
