(function() {
	// globals
	window.EWO.Election = window.EWO.Election || {};


	// module functions
	EWO.MD({
		global: EWO.Election,
		name: 'electionConnectedLists',
		containerClass: 'js-electionConnectedLists',
		attachToElement: '.js-election-container',
		listTemplate: '/app/election/connectedLists/templates/connectedLists.ejs',
		createOrEditTemplate: '/app/election/connectedLists/templates/connectedListsCreateOrEdit.ejs',
		createOrEditValidationRules: {
			rules: {
				descDe: 'required',
				descIt: 'required',
				listsIds: 'required'
			}
		},
		createOrEditAdditionalData: function(id, filter) {
			return $.get(EWO.config.apiUrlPrefix + '/links/linkablelists?electionId=' + filter.electionId + '&constituencyId=' + filter.constituencyId + '&connectionId=' + (id ? id : ''));
		},
		createOrEditCallback: function(id, dataSource) {
			var liste = dataSource.data[0].rows;
			var selected = dataSource.detail.lists;
			var currMayor = null;
			if (selected && selected.length > 0) {
				currMayor = selected[0].mayorId;
			}
			$('#lists option').each(function(index, element) {
				if (index > 0) {
					for (var i = 0; i < liste.length; i++) {
						if (liste[i].id == element.value) {
							$(element).attr('data-mayor', liste[i].mayorId);
							if (currMayor != null && currMayor != liste[i].mayorId) {
								$(element).attr('disabled', 'disabled');
							}
							break;
						}
					}
				}
			});
			$('#lists').select2();
		},
		beforeSaveCallback: function(detail) {
			if (detail.listsIds && !$.isArray(detail.listsIds)) {
				detail.listsIds = [detail.listsIds];
			}
			return detail;
		},
		apiRoot: EWO.config.apiUrlPrefix + '/links',
		routingRoot: '/election/:electionId/constituency/:constituencyId/linkedlists'
	});


	//events
	$(document).on('select2:select', '.js-electionConnectedLists .connListSelector', function(ev) {
		var data = ev.params.data;
		var el = $(ev.currentTarget);
		ev.preventDefault();

		if (ev.target.selectedOptions.length == 1 && data.element.attributes['data-mayor'] && data.element.attributes['data-mayor'].value != '') {
			var selecmayor = data.element.attributes['data-mayor'].value;
			$('#defaultMayorId').val(selecmayor);

			for (var i = 1;i < ev.target.options.length;i++) {
				if (ev.target.options[i].attributes['data-mayor'].value != selecmayor) {
					$(ev.target.options[i]).attr('disabled', 'disabled');
				}
			}
		}
	});

	$(document).on('select2:unselect', '.js-electionConnectedLists .connListSelector', function(ev) {
		var data = ev.params.data;
		var el = $(ev.currentTarget);
		ev.preventDefault();

		if (ev.target.selectedOptions.length == 0) {
			for (var i = 0;i < ev.target.options.length;i++) {
				ev.target.options[i].removeAttribute('disabled');
			}
		}
	});

	// module startup

	//setup routing
})();
