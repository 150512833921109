(function() {
	// globals
	window.EWO.Election = window.EWO.Election || {};


	// module functions
	EWO.Election.renderStatisticsRunOff = function(ctx) {
		var urlQueryString = new URLSearchParams(ctx.querystring);
		var filter = Object.fromEntries(urlQueryString);
		$.extend(filter, ctx.params);

		$.when(
			ejs.preloadTemplate('/app/election/statisticsRunOff/templates/statisticsRunOff.ejs'),
			$.get(EWO.config.apiUrlPrefix + '/survey/statisticsPremier?turn=2', filter),
			$.get(EWO.config.apiUrlPrefix + '/constituencies/municipalitiesforconstituencies', filter)
		)
		.done(function(templateUrl, list, municipalities) {
			$('.js-election-container').html(ejs.rr(templateUrl, {
				list: list[0],
				data: municipalities,
				filter: filter
			}));

			$('.sortable').DataTable({'paging': false, 'lengthChange': false, 'info': false });
		});
	};


	//events
	$(document).on('submit', '.js-electionStatisticsRunOff .js-filterform', function(ev) {
		var el = $(ev.currentTarget);
		ev.preventDefault();
		var filter = el.serialize();
		page(document.location.pathname + (filter ? '?' + filter : ''));
	});

	$(document).on('click', '.js-electionStatisticsRunOff .js-csv-export', function() {
		var filterForm = $('.js-electionStatisticsRunOff .js-filterform');
		var expFilter = filterForm.serialize();
		window.location.href = EWO.config.apiUrlPrefix + '/survey/statisticsPremierCsvExport?' + expFilter;
	});

	// module startup

	//setup routing
	page('/election/:electionId/constituency/:constituencyId/expRunoff', EWO.Election.renderStatisticsRunOff);
})();
