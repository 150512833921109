(function() {
	// globals
	window.EWO.Election = window.EWO.Election || {};


	// module functions
	EWO.Election.renderCouncil = function(ctx) {
		var urlQueryString = new URLSearchParams(ctx.querystring);
		var filter = Object.fromEntries(urlQueryString);
		$.extend(filter, ctx.params);

		$.when(
			ejs.preloadTemplate('/app/election/council/templates/council.ejs'),
			$.get(EWO.config.apiUrlPrefix + '/council', filter),
			$.get(EWO.config.apiUrlPrefix + '/constituencies/municipalitiesforconstituencies', filter)
		)
		.done(function(templateUrl, res, municipalities) {
			$('.js-election-container').html(ejs.rr(templateUrl, {res: res[0], data: municipalities, filter: filter}));
		});
	};

	//events
	$(document).on('submit', '.js-electionCouncil .js-filterform', function(ev) {
		var el = $(ev.currentTarget);
		ev.preventDefault();
		var filter = el.serialize();
		page(document.location.pathname + (filter ? '?' + filter : ''));
	});

	$(document).on('click', '.js-electionCouncil .js-recalculate', function() {
		var filterForm = $('.js-electionCouncil .js-filterform');
		var expFilter = filterForm.serialize();
		if (expFilter=== "") {
			expFilter ="constituencyId=" + $('input[name=constituencyId]').val();
		}
		$.ajax({
			type: 'POST',
			url: EWO.config.apiUrlPrefix + '/council/compute?' + expFilter,
			contentType: 'application/json; charset=UTF-8'
		})
		.done(function() {
			page(document.location.pathname);
		});
	});

	$(document).on('click', '.js-electionCouncil .js-publish', function() {
		var filterForm = $('.js-electionCouncil .js-filterform');
		var expFilter = filterForm.serialize();
		$.ajax({
			type: 'POST',
			url: EWO.config.apiUrlPrefix + '/council/publish?' + expFilter,
			contentType: 'application/json; charset=UTF-8'
		})
		.done(function() {
			page(document.location.pathname);
		});
	});

	$(document).on('click', '.js-electionCouncil .js-exportdocument', function(ev) {
		var el = $(ev.currentTarget);
		var type = $(el).data('type');
		var constituencyId = $('input[name="constituencyId"]').val();
		window.location.href = EWO.config.apiUrlPrefix + '/council/exportdoc?constituencyId=' + constituencyId + '&format=' + type;
	});


	// module startup

	//setup routing
	page('/election/:electionId/constituency/:constituencyId/getCouncil', window.EWO.Election.renderCouncil);
})();
