(function() {
	// globals
	window.EWO.Election = window.EWO.Election || {};


	// module functions
	EWO.MD({
		global: EWO.Election,
		name: 'electionStatusList',
		containerClass: 'js-electionStatusList',
		attachToElement: '.js-election-container',
		listTemplate: '/app/election/statusLists/templates/statusLists.ejs',
		createOrEditTemplate: '/app/election/statusLists/templates/statusListsCreateOrEdit.ejs',
		createOrEditValidationRules: {
			rules: {
				votes: {
					required: true,
					number: true
				},
				valids: {
					required: true,
					number: true
				},
				invalids: {
					required: true,
					number: true
				},
				blanks: {
					required: true,
					number: true
				},
				nulls: {
					required: true,
					number: true,
					equalTo: '#sumOfNullDetails'
				},
				contested: {
					required: true,
					number: true
				},
				nulls_Invalid: { required: true, number: true},
				nulls_NullVoteOnly: { required: true, number: true},
				nulls_Withdrawn: { required: true, number: true},
				nulls_NotReturned: { required: true, number: true}
			}
		},
		createOrEditAdditionalData: function(id, filter, qs) {
			return $.get(EWO.config.apiUrlPrefix + '/constituencies/municipalitiesforconstituencies?constituencyId=' + filter.constituencyId + '&electionId=' + filter.electionId);
		},
		listAdditionalData: function(filter) {
			var d = $.Deferred();
			$.when(
				$.get(EWO.config.apiUrlPrefix + '/constituencies/municipalitiesforconstituencies?constituencyId=' + filter.constituencyId + '&electionId=' + filter.electionId)
			)
			.done(function(municipalities) {
				d.resolve({
					municipalities: municipalities.rows
				});

				if(municipalities != undefined && municipalities.rows.length == 1){
					filter.municipalityId = municipalities.rows[0].id;
				}
			}) 
			.fail(function() {
				d.reject();
			});
			return d;
		},
		listCallback: function() {
			$('#municipalityId').select2();
		},
		createOrEditCallback: function(id, data) {
			calcSum();
			if (data.detail != undefined && data.detail.listErrors != undefined) {
				markPossibleErrors(data.detail.listErrors);
			}
			$('#municipalityId').select2();
		},
		beforeSaveCallback: function(data) {
			data.values = $('.n-votes').map(function(i, e) {
				return {
					id: Number($(e).attr('data-id')),
					votes: Number($(e).val())
				};
			}).toArray();
			return data;
		},
		afterSaveErrorCallback: function(data) {
			page(document.location);
		},
		datatablesOptions: {
			pageLength: 100,
			lengthMenu: [[50, 100, 500, -1], [50, 100, 500, "Tutti"] ]
		},
		apiRoot: EWO.config.apiUrlPrefix + '/survey/statusList',
		routingRoot: '/election/:electionId/constituency/:constituencyId/listvotes'
	});

	var calcSum = function() {
		tot = 0;
		$('.n-votes').each(function() {
			tot += Number(this.value);
		});
		$('#sumListVotes').text(tot);
	};
	var calcNullsSum = function() {
		totNulls = 0;
		$('.null-det').each(function() {
			totNulls += Number(this.value);
		});
		if (totNulls >= 0) {
			$('#sumOfNullDetails').val(totNulls);
		} else {
			$('#sumOfNullDetails').val($('nulls').text);
		}
	};


	var markPossibleErrors = function(errors) {

		for (i = 0; i < errors.lenght; i++) 		{
			for (j = 0; j < errors[i].relatedLists.lenght; j++) {
				$('input[data-id=' + element[i].relatedLists[j].list.id + ']').addClass('is-invalid');
			}
		}
	};

	//events
	$(document).on('change', '.js-electionStatusList .n-votes', function(ev) {
		calcSum();
	});

	$(document).on('change', '.js-electionStatusList .null-det', function(ev) {
		calcNullsSum();
	});
	// module startup

	//setup routing
})();
