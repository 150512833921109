(function() {
	// globals
	window.EWO.Profile = {};

	// module functions
	EWO.Profile.renderUserProfile = function() {
		$.when(
			ejs.preloadTemplate('/app/profile/templates/profile.ejs'),
			$.get(EWO.config.apiUrlPrefix + '/users/profile/' + EWO.User.id)
		)
		.done(function(templateUrl, profile) {
			$('.js-page-container').html(ejs.rr(templateUrl, {profile: profile[0]}));
			$('.js-editform').validate({
				rules: {
					email: {
						required: true,
						email: true
					},
					language: 'required',
					password: {
						required: function() {
							return $('#change-password').is(':checked');
						}
					},
					'newpassword': {
						required: function() {
							return $('#change-password').is(':checked');
						},
						passwordstrenght: function() {
							if ($('#change-password').is(':checked')) {
								return 75;
							} else {
								return 0;
							}
						}
					},
					'confirm-password': {
						required: function() {
							return $('#change-password').is(':checked');
						},
						equalTo: '#newpassword'
					}
				},
				ignore: '#username'
			});
		});

	};

	// module events
	$(document).on('change', '.js-profile #change-password', function() {
		if ($('.js-changepassword').hasClass('d-none')) {
			$('.js-changepassword').removeClass('d-none');
			$('#change-password').prop('checked', true);
		} else {
			$('.js-changepassword').addClass('d-none');
			$('#change-password').prop('checked', false);
		}
		$('.js-profile .js-editform').valid();
	});

	$(document).on('submit', '.js-profile .js-editform', function(ev) {
		formData = EWO.Utils.formToObject($(ev.currentTarget));
		ev.preventDefault();
		user = formData;
		delete user['confirm-password'];
		if (!$('#change-password').is(':checked')) {
			delete user.password;
			delete user.newpassword;
		}

		$.ajax({
			type: 'PUT',
			url: EWO.config.apiUrlPrefix + '/users/' + EWO.User.id,
			data: JSON.stringify(user),
			contentType: 'application/json; charset=UTF-8'
		})
		.done(function() {
			PNotify.success(i18next.t('Profile updated'));
			page('/');
		});
	});

	// module startup

	//setup routing
	page('/profile', EWO.Profile.renderUserProfile);
}
)();
