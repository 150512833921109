(function() {

	if (isValidBrowser == false) {
		return;
	}

	// globals
	EWO.Utils = {};
	EWO.User = {};


	// utility functions
	// EWO.Utils.someUtilityFunction = function() {}
	// EWO.Utils.someOtherUtilityFunction = function() {}

	EWO.Utils.formToObject = function(o) {
		var form = {};
		$.each($(o).serializeArray(), function(i, e) {
			if (form[e.name] && !$.isArray(form[e.name])) {
				form[e.name] = [form[e.name]];
			}

			if (form[e.name]) {
				form[e.name].push(e.value);
			} else {
				form[e.name] = e.value;
			}
		});

		return form;
	};

	// app functions

	// app startup

	// global error handler
	var globalErrorHandler = function(options) {
		var defaults = {
			message: '',
			type: 'error'
		};
		options = $.extend({}, defaults, options);

		var standardMsg = 'There was an error. Sorry 😢';
		var standardMsg = i18next.t(standardMsg) || standardMsg;
		var m = standardMsg;
		try {
			r = $.parseJSON(options.message);
			m = r['message'] || standardMsg;
		} catch(err) {
			if (options.error && window.Rollbar) {
				Rollbar.error(options.message || 'This error has no message', options.error);
			}
		}

		PNotify[options.type](m);
	};

	window.onerror = function(message, source, lineno, colno, error) {
		if (window.Rollbar) {
			Rollbar.error(message, error);
		}
		globalErrorHandler({message: message});
		return false;
	};

	$(document).ajaxError(function(e, request, settings) {
		if (settings.handled === true || request.handled === true) {
			return;
		}

		if (request.status === 403) {
			globalErrorHandler({
				message: request.responseText ? request.responseText : JSON.stringify({message: i18next.t('You are not authorized to do this.')}),
				type: 'notice'
			});

		} else if (request.status === 401) {
			document.location = '/login?targetUrl=' + encodeURIComponent(document.location);
		} else {
			globalErrorHandler({
				message: request.responseText,
				error: e
			});
		}
	});

	var startup = function() {
		// set language
		i18next
		.use(i18nextBrowserLanguageDetector)
		.use(i18nextXHRBackend)
		.init({
			detection: {
				order: ['querystring', 'cookie', 'navigator'],
				lookupQuerystring: 'lang',
				lookupCookie: 'current-language',
				caches: ['cookie'],
				cookieMinutes: 5256000
			},
			nsSeparator: false, // namespace separator
			keySeparator: false, // key separator
			whitelist: ['en', 'de', 'it'],
			load: 'languageOnly',
			fallbackLng: 'de',
			backend: {
				loadPath: '/i18n/{{lng}}.json'
			}
		})
		.then(function() {
			//if we are here, EWO.User is set, and the user is logged in

			// language initialized
			// if there is a lang query string parameter, remove it and reload: we save the language in a cookie so the url stays nice
			var urlQueryString = new URLSearchParams(window.location.search);
			if (urlQueryString.has('lang')) {
				urlQueryString.delete('lang');
				var newUrl = [location.protocol, '//', location.host, location.pathname].join('') +
					urlQueryString.toString() +
					window.location.hash;
				window.location = newUrl;

				return;
			}

			// form validation for jquery validate defaults
			$.extend($.validator.messages, $.validator.localizedmessages[i18next.language]);
			jQuery.validator.setDefaults({
				validClass: 'is-valid',
				errorElement: 'div',
				errorPlacement: function(error, element) {
					error.addClass('invalid-feedback');
					element.parent().append(error);
				},
				highlight: function(element, errorClass, validClass) {
					$(element).addClass('is-invalid');
					$(element).removeClass('is-valid');
				},
				unhighlight: function(element, errorClass, validClass) {
					$(element).removeClass('is-invalid');
					$(element).addClass('is-valid');
				},
				onfocusout: function(element) {
					this.element(element);
				},
				ignore: ':hidden, :disabled, [readonly=readonly], .novalidate'
			});

			//validation for select2
			$(document).on('change', '.select2-hidden-accessible', function() {
				try {
					$(this).rules();	//check if there are validation rules on this field before calling validate
					$(this).valid();
				} catch(e) {}
			});

			//select2 config
			$.fn.select2.defaults.set('theme', 'bootstrap4');
			$.fn.select2.defaults.set('width', 'style');
			$.fn.select2.defaults.set('allowClear', true);
			$.fn.select2.defaults.set('placeholder', i18next.t('Select a value'));

			//datepicker config
			var dplang = i18next.language === 'en' ? 'en-GB' : i18next.language;
			$.extend($.fn.datepicker.defaults, $.fn.datepicker.dates[dplang]);
			$.fn.datepicker.defaults['daysOfWeekHighlighted'] = '6,0',
			$.fn.datepicker.defaults.language = dplang;

			//moment config
			var mlang = i18next.language === 'en' ? 'en-gb' : i18next.language;
			moment.locale(mlang);

			//datatable config
			if ($.inArray(i18next.language, ['de', 'it']) !== -1) {
				$.extend(true, $.fn.dataTable.defaults, {
					language: $.fn.dataTable.localizedmessages[i18next.language]
				});
			}

			//configure nprogress
			$.ajaxPrefilter(function(options, _, jqXHR) {
				if (!options.noprogress) {
					NProgress.start();
					jqXHR.always(NProgress.done);
				}
			});

			//configure pnotify styling
			PNotify.defaults.styling = 'bootstrap4';
			PNotify.defaults.icons = 'fontawesome4';

			$.extend(true, $.fn.dataTable.defaults, {
				searching: false,
				paging: true,
				pageLength: 25,
				columnDefs: [
					{
						targets: 'no-sort',
						orderable: false
					}
				]
			});

			// render main shell
			EWO.Shell.renderShell()
			.done(function() {
				page('*', EWO.Shell.render404);
				page();

				//submenu navbar
				$('.dropdown-menu a.dropdown-toggle').on('click', function(e) {
					if (!$(this).next().hasClass('show')) {
						$(this).parents('.dropdown-menu').first().find('.show').removeClass('show');
					}
					var $subMenu = $(this).next('.dropdown-menu');
					$subMenu.toggleClass('show');

					$(this).parents('li.nav-item.dropdown.show').on('hidden.bs.dropdown', function(e) {
						$('.dropdown-submenu .show').removeClass('show');
					});
					return false;
				});

			});

		});
	};

	//get current user
	if (location.pathname == '/login' || location.pathname.startsWith('/credentials')) {
		page();
	} else {
		$.ajax({
			url: EWO.config.apiUrlPrefix + '/users/current',
			method: 'GET',
			handled: true
		})
		.done(function(response) {
			EWO.User = response;

			if (window.Rollbar) {
				Rollbar.configure({
					payload: {
						person: EWO.User,
						environment: EWO.config.environment
					}
				});
			}

			startup();
		})
		.fail(function(response) {
			page({dispatch: false});
			page('/login?targetUrl=' + encodeURIComponent(document.location));
		});
	}

})();
