(function() {
	// globals
	window.EWO.Election = window.EWO.Election || {};

	// module functions
	EWO.MD({
		global: EWO.Election,
		name: 'electionSectCommunications',
		containerClass: 'js-electionSectCommunications',
		attachToElement: '.js-election-container',
		listTemplate: '/app/election/sectCommunications/templates/sectCommunications.ejs',
		createOrEditTemplate: '/app/election/sectCommunications/templates/sectCommunicationsCreateOrEdit.ejs',
		createOrEditValidationRules: {
			rules: {
				maleVoters: {
					required: true,
					number: true
				},
				femaleVoters: {
					required: true,
					number: true
				},
				totalVoters: {
					required: true,
					number: true
				}
			}
		},
		listAdditionalData: function(filter) {
			var d = $.Deferred();
			$.when(
				$.get(EWO.config.apiUrlPrefix + '/constituencies/municipalitiesforconstituencies?constituencyId=' + filter.constituencyId + '&electionId=' + filter.electionId),
				$.get(EWO.config.apiUrlPrefix + '/communications/types?electionId=' + filter.electionId)
			)
			.done(function(municipalities, types) {
				d.resolve({
					municipalities: municipalities[0].rows,
					types: types[0].rows
				});

				if(municipalities.length > 0 && municipalities[0].rows.length == 1){
					filter.municipalityId = municipalities[0].rows[0].id;
				}
			}) 
			.fail(function() {
				d.reject();
			});
			return d;
		},
		listCallback: function() {
			$('#municipalityId').select2();
		},
		datatablesOptions: {
			paging: false
		},
		apiRoot: EWO.config.apiUrlPrefix + '/communications',
		routingRoot: '/election/:electionId/constituency/:constituencyId/sectCommunications'
	});

	//events

	// module startup

	//setup routing
})();
