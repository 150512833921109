(function() {
	// globals
	window.EWO.Election = window.EWO.Election || {};
	window.EWO.Election.Constituencies = {};

	// module functions
	EWO.Election.Constituencies.renderElectionChooseConstituency = function(ctx) {
		var electionId = ctx.params.id;
		$.get(EWO.config.apiUrlPrefix + '/constituencies?electionId=' + electionId)
		.done(function(constituencies) {
			$('.js-page-container').html(ejs.rr('/app/election/constituencies/templates/chooseConstituency.ejs', {electionId: electionId, constituencies: constituencies}));
		});
	};

	EWO.Election.Constituencies.renderConstituencyCreateOrEdit = function(ctx) {
		var id = ctx.params.id;
		var constituencyId = ctx.params.constituencyId;

		var render = function(detail, municipalities, container) {
			$.when(
				ejs.preloadTemplate('/app/election/constituencies/templates/chooseConstituencyCreateOrEdit.ejs'),
				$.get(EWO.config.apiUrlPrefix + '/types/constituencies'),
				$.get(EWO.config.apiUrlPrefix + '/statuses')
			)
			.done(function(templateUrl, types, statuses) {
				$(container).html(ejs.rr(templateUrl, {constituency: detail, municipalities: municipalities.rows, types: types[0].rows, statuses: statuses[0].rows}));

				$('#municipalityId').select2();
				$('#typeId').select2();
				$('#statusId').select2();

				//validation rules
				$('.js-editform').validate({
					rules: {
						municipalityId: 'required',
						typeId: 'required',
						statusId: 'required',
						citizens: 'number'
					}
				});
			});
		};

		if (constituencyId) {
			$.when(
				$.get(EWO.config.apiUrlPrefix + '/constituencies/' + constituencyId),
				$.get(EWO.config.apiUrlPrefix + '/municipalities')
			)
			.done(function(detail, municipalities) {
				render(detail[0], municipalities[0], '.js-election-container');
			});
		} else {
			$.get(EWO.config.apiUrlPrefix + '/constituencies/municipalities?electionId=' + id)
			.done(function(municipalities) {
				render({ electionId: id }, municipalities, '.js-page-container');
			});
		}
	};

	// module events
	$(document).on('submit', '.js-constituencies .js-editform', function(ev) {
		var detailForm = $(ev.currentTarget);
		ev.preventDefault();

		var detail = EWO.Utils.formToObject(detailForm);

		$.ajax({
			type: (detail.id && detail.id > 0 ? 'PUT' : 'POST'),
			url: EWO.config.apiUrlPrefix + '/constituencies' + (detail.id ? '/' + detail.id : ''),
			data: JSON.stringify(detail),
			contentType: 'application/json; charset=UTF-8'
		})
		.done(function() {
			page('/election/' + detail.electionId + (detail.id ? '/constituency/' + detail.id + '/' : '/constituencies'));
		});
	});

	$(document).on('click', '.js-constituencies .js-delete', function(ev) {
		ev.preventDefault();
		var detailForm = $(ev.currentTarget).parents('.js-editform');
		var detail = EWO.Utils.formToObject(detailForm);

		var notice = PNotify.notice({
			title: i18next.t('Confirm'),
			text: i18next.t('Do you really want to delete?'),
			hide: false,
			modules: {
				Confirm: {
					confirm: true
				},
				Buttons: {
					closer: false,
					sticker: false
				}
			}
		});
		notice.on('pnotify.confirm', function() {
			$.ajax({
				type: 'DELETE',
				url: EWO.config.apiUrlPrefix + '/constituencies/' + detail.id
			})
			.done(function() {
				page('/election/' + detail.electionId + '/constituencies');
			});
		});
	});

	$(document).on('click', '.js-constituencies .js-export', function(ev) {
		var el = $(ev.currentTarget);
		var type = $(el).data('type');
		var electionId = $('input[name="electionId"]').val();
		window.location.href = EWO.config.apiUrlPrefix + '/constituencies/export?electionId=' + electionId + '&format=' + type;
	});

	// module startup


	//setup routing
	page('/election/:id/constituencies', EWO.Election.Constituencies.renderElectionChooseConstituency);
	page('/election/:id/constituencies/add', EWO.Election.Constituencies.renderConstituencyCreateOrEdit);
})();
