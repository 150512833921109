(function() {
	// globals
	window.EWO.Login = {};

	// module functions
	EWO.Login.renderLogin = function(ctx) {
		var urlQueryString = new URLSearchParams(ctx.querystring);
		var loggedout = urlQueryString.has('logged-out');

		ejs.preloadTemplate('/app/login/templates/login.ejs')
		.done(function(templateUrl) {
			$('.js-main-content').html(ejs.rr(templateUrl));

			if (loggedout) {
				$('#logged-out').attr('style', '');
			}
		});
	};

	EWO.Login.logout = function() {
		$.ajax({
			url: EWO.config.apiUrlPrefix + '/users/logout',
			method: 'POST',
			handled: true
		})
		.done(function(response) {
			page('/login?logged-out=✓');
		});
	};

	// module events
	$(document).on('onblur', '.js-login #username', function(ev) {
		var field = $('#username');
		field.value = field.value.trim();
	});

	$(document).on('keydown mousedown', '.js-login #password', function(ev) {
		if (ev.originalEvent.getModifierState && ev.originalEvent.getModifierState('CapsLock')) {
			$('#passwordCapsLock').show();
		} else {
			$('#passwordCapsLock').hide();
		}
	});

	$(document).on('submit', '.js-login #form-signin', function(ev) {
		ev.preventDefault();

		$('#logged-out').attr('style', 'visibility: hidden');

		var username = $('[name="username"]').val().replace('.', '');
		var password = $('[name="password"]').val();

		var handlefailure = function(response) {

			$('strong', '#up-wrong').text('Username or password wrong');

			$('#up-wrong').attr('style', '');
			$('#up-wrong').fadeIn(100).fadeOut(100).fadeIn(100).fadeOut(100).fadeIn(100);

			$('button[type="submit"]', '#form-signin').prop('disabled', false);
			$('button[type="submit"]', '#form-signin').html(
				'Sign in'
			);

			if (response) {
				PNotify['error'](response.responseJSON.message);
			}
		};

		// disable button
		$('button[type="submit"]', '#form-signin').prop('disabled', true);
		// add spinner to button
		$('button[type="submit"]', '#form-signin').html(
			'<span class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span> Signing in...'
		);

		$.ajax({
			url: EWO.config.apiUrlPrefix + '/users/authenticate',
			method: 'POST',
			contentType: 'application/json; charset=UTF-8',
			handled: true,
			data: JSON.stringify({
				username: username,
				password: password
			})
		})
		.done(function(response) {
			var urlQueryString = new URLSearchParams(location.search);
			var targetUrl = urlQueryString.get('targetUrl') || '/';
			location.href = decodeURIComponent(targetUrl);
		})
		.fail(function(response) { handlefailure(response); });
		//.fail(handlefailure);
	});


	// module startup
	//setup routing
	page('/login', EWO.Login.renderLogin);
	page('/login/logout', EWO.Login.logout);
})();
