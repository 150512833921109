(function() {
	// globals
	EWO.Info = {};

	// utility functions

	// app functions
	EWO.Info.rederRelease = function() {
		$.get('/version.json')
		.done(function(version) {
			$('.js-page-container').html(ejs.rr('/app/info/release/templates/release.ejs', {version: version}));
		});
	};

	// events
	$(document).on('click', '.js-info-release .js-refresh', function(ev) {
		ev.preventDefault();
		location.reload(true);
	});

	// startup

	//routes
	page('/info/release', EWO.Info.rederRelease);
})();
