(function() {
	// globals
	window.EWO.Shell = {};
	EWO.Shell.menu = {};
	EWO.Shell.notificationTimer = false;


	// module functions
	//render a select
	EWO.Shell.select = function(options) {
		/*
		example options:
		{
			name: 'typeId',
			id: 'typeId',
			options: [{desc: 'description 1', id: 1}, {desc: 'description 2', id: 2}, {desc: 'description 3', id: 3}],
			selectedId: 2,
			multiple: true/false
			allowEmpty: true/false,
			classes: 'some-extra-css classes to-add',
			selectedIfOnlyOne: true/false //if there is only one element in the list, select it
		}
		*/

		if (options.options && options.options.length === 1 && options['selectedIfOnlyOne'] === true) {
			options.selectedId = [options.options[0].id];
		}

		if (!$.isArray(options.selectedId)) {
			options.selectedId = [options.selectedId];
		}
		
		//just to make sure it disappears
		$('.footerPresident').html('');
		$('.footerPresident').hide('');

		return ejs.rr('/app/shell/templates/controls/select.ejs', {options: options});
	};

	EWO.Shell.ellipsis = function(text, maxLength) {
		if (typeof maxLength === 'undefined') {
			maxLength = 9000; //a large number
		}
		if (typeof text === 'undefined') {
			return '';
		}
		if (text.length <= maxLength) {
			return text;
		}

		var xMaxFit = maxLength - 1;
		var xTruncateAt = text.lastIndexOf(' ', xMaxFit);
		if (xTruncateAt == -1 || xTruncateAt < maxLength / 2) {
			xTruncateAt = xMaxFit;
		}

		return text.substr(0, xTruncateAt) + '…';
	};

	EWO.Shell.newNotificationsLayout = function(notifications) {
		$('.js-notifications-nav').addClass(['bg-danger', 'text-white']);
		$('.js-notifications-badge').html($('.js-notification-new').length);

		if ($('.js-new-notifications-badge').length > 0) {
			$('.js-new-notifications-badge').html($('.js-notification-new').length);
		} else {
			$('.navbar-toggler-icon').after('<span class="badge badge-danger js-new-notifications-badge">' + $('.js-notification-new').length + '</span>');
		}
	};

	EWO.Shell.oldNotificationsLayout = function() {
		$('.js-notifications-nav').removeClass(['bg-danger', 'text-white']);

		$('.js-new-notifications-badge').remove();
	};

	EWO.Shell.notifications = function() {
		var handleNotifications = function() {
			$.get({
				url: EWO.config.apiUrlPrefix + '/notifications/currentuser',
				handled: true,
				noprogress: true
			})
			.done(function(notifications) {
				notifications = notifications.rows;

				if (notifications.length > 0) {
					ejs.preloadTemplate('/app/shell/templates/notifications.ejs', notifications)
					.done(function(templateUrl) {
						if ($('.js-notifications-dd').length > 0) {
							$('.js-notifications-dd').replaceWith(ejs.rr(templateUrl, {notifications: notifications}));
						} else {
							$('.js-navbar-profile').after(ejs.rr(templateUrl, {notifications: notifications}));
						}

						if ($('.js-notification-new').length > 0) {
							EWO.Shell.newNotificationsLayout();
						} else {
							EWO.Shell.oldNotificationsLayout();
						}
					});
				} else {
					$('.js-notifications-dd').remove();
				}
			});
		};
		if (EWO.Shell.notificationTimer) {
			handleNotifications();
		} else {
			EWO.Shell.notificationTimer = setInterval(handleNotifications, 10000);
			handleNotifications();
		}
	};

	EWO.Shell.renderShell = function() {
		document.title = i18next.t('EWO');

		EWO.Symbols = {
			statusError: '<span class="status fa fa-times-circle-o"></span>', // circle with X
			statusSaved: '<span class="status fa fa-circle-o"></span>', // circle
			statusChecked: '<span class="status fa fa-check"></span>', // check
			statusPublished: '<span class="status fa fa-check"></span>', //cloud
			statusPreferred: '<span class="status">⭐</span>' //star
			//statusPreferred: '<span class="status" title="' + i18next.t('published') + '">&hearts; </span>' //heart
		};

		EWO.Symbols.getStatus = function(status) {
			if (status.status == 16) {
				return '<a class="js-unpublish" href="javascript:///" data-id="' + status.id + '" title="' + status.statusDesc + '">' + EWO.Symbols.statusChecked + '</a>';
			} else if (status.status == 2) {
				return '<a class="js-publish" href="javascript:///" data-id="' + status.id + '" title="' + status.statusDesc + '">' + EWO.Symbols.statusSaved + '</a>';
			} else if (status.status < 0) {
				return '<span class="text-danger" title="' + status.statusDesc + '">' + EWO.Symbols.statusError + '</span>';
			}
		};

		EWO.Symbols.getRegistriesStatus = function(status, canDoAction) {
			var action = "";
			if (status.status == 2) {
				if (canDoAction === false) {
				   action = " ";
				}
				else
				{
				   action = 'class="js-unpublish" ';
				}
				return '<a ' + action + ' href="javascript:///" data-id="' + status.id + '" title="' + i18next.t('Check') + '">' + EWO.Symbols.statusChecked + '</a>';
			} else if (status.status == 1) {
				if (canDoAction === false) {
					action = " ";
				 }
				 else
				 {
					action = 'class="js-publish" ';
				 }
				 return '<a ' + action + ' href="javascript:///" data-id="' + status.id + '" title="' + i18next.t('Uncheck') + '">' + EWO.Symbols.statusSaved + '</a>';
			} else if (status.status < 0) {
				return '<span class="text-danger" title="Error">' + EWO.Symbols.statusError + '</span>';
			}
		};

		EWO.Symbols.roleDesc = function(roleId){
			switch(roleId){
				case 1: return i18next.t('President');
				case 2: return i18next.t('Secretary');
				case 3: return i18next.t('Scrutineer');
				case 4: return i18next.t('VicePresident');
				default: return i18next.t('No Role');
			}
		}

		var d = $.Deferred();

		$.when(
			ejs.preloadTemplate('/app/shell/templates/main.ejs'),
			ejs.preloadTemplate('/app/shell/templates/menu.ejs'),

			//templates that should always be ready go here:
			ejs.preloadTemplate('/app/shell/templates/controls/select.ejs')
		).done(function(templateUrl) {
			$.get(EWO.config.apiUrlPrefix + '/menu')
			.done(function(menu) {
				EWO.Shell.menu = menu;
				$('.js-main-content').html(ejs.rr(templateUrl, {menu: menu}));
				EWO.Shell.notifications(); //start notifications timer
				d.resolve();
			});
		});

		return d;
	};

	EWO.Shell.renderHomePage = function() {
		$('.js-page-container').html(ejs.rr('/app/shell/templates/home.ejs'));
	};

	EWO.Shell.render404 = function() {
		$('.js-page-container').html(ejs.rr('/app/shell/templates/404.ejs'));
	};

	// module events
	$(document).on('click', '#navbarSupportedContent a:not([data-toggle])', function() {
		$('#navbarSupportedContent').collapse('hide');
	});

	$(document).on('shown.bs.dropdown', '.js-notifications-dd', function(el) {
		clearInterval(EWO.Shell.notificationTimer);
		EWO.Shell.notificationTimer = false;
	});

	$(document).on('hide.bs.dropdown', '.js-notifications-dd', function(el) {
		EWO.Shell.notifications();
	});

	$(document).on('click', '.js-notification', function(ev) {
		var el = $(ev.currentTarget);
		var id = el.attr('data-id');
		var text = el.attr('data-text');
		PNotify.info({
			text: text,
			textTrusted: true
		});
		$.ajax({
			url: EWO.config.apiUrlPrefix + '/notifications/currentuser/' + id,
			type: 'PUT',
			data: {},
			contentType: 'application/json; charset=UTF-8'
		})
		.done(EWO.Shell.notifications);
	});

	$(document).on('click', '.js-contactPresident', function(ev) {
		var el = $(ev.currentTarget);
		ev.preventDefault();
		var phone = el.attr('data-phone');

		navigator.clipboard.writeText(phone);

		PNotify.success(i18next.t('Number copied'));
	});

	$(document).on('click', '.js-getContactPresident', function(ev) {
		var el = $(ev.currentTarget);
		ev.preventDefault();
		var sectionId = el.attr('data-sectionId');
		var constituencyId = el.attr('data-constituencyId');

		$.get(EWO.config.apiUrlPrefix + '/people/sectionpresident/' + constituencyId + '/' + sectionId)
			.done(function(president) {
				if(president != undefined){

					navigator.clipboard.writeText(president.phone);
					PNotify.success(i18next.t('Number copied'));
				}
				else
				{
					PNotify.error(i18next.t('President not set'));
				}
			});
	})

	// module startup

	//setup routing
	page('/', EWO.Shell.renderHomePage);
})();
