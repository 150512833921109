(function() {
	// globals
	window.EWO.Election = window.EWO.Election || {};


	// module functions
	EWO.MD({
		global: EWO.Election,
		name: 'electionMayors',
		containerClass: 'js-electionMayors',
		attachToElement: '.js-election-container',
		listTemplate: '/app/election/mayors/templates/mayors.ejs',
		createOrEditTemplate: '/app/election/mayors/templates/mayorsCreateOrEdit.ejs',
		createOrEditValidationRules: {
			rules: {
				num: {
					required: true,
					number: true
				},
				firstname: 'required',
				familyname: 'required',
				sex: 'required',
				runfor: 'required',
				languagegroup: 'required',
				birthplaceselector: {
					required: true
				},
				birthMunicipalityId: {
					required: function(element) {
						return $('#birthplaceselector').val() === '1';
					}
				},
				birthPlace: {
					required: function(element) {
						return $('#birthplaceselector').val() === '2';
					}
				},
				birthday: {required: true}
			}
		},
		createOrEditAdditionalData: function(id, filter, qs, detail) {
			delete filter.id;
			var d = $.Deferred();
			$.when(
				$.get(EWO.config.apiUrlPrefix + '/types/genders'),
				$.get(EWO.config.apiUrlPrefix + '/mayors/types?constituencyid=' + filter.constituencyId + '&onlyMayor=true'),
				$.get(EWO.config.apiUrlPrefix + '/types/languagegroups'),
				$.get(EWO.config.apiUrlPrefix + '/types/allprovinces'),
				$.get(EWO.config.apiUrlPrefix + '/types/nations'),
				$.get(EWO.config.apiUrlPrefix + '/types/municipalitybyprovince?provId=' + (detail ? (detail.birthProvinceId != undefined ? detail.birthProvinceId : '-1') : '-1'))
			)
			.done(function(genders, types, groups, provinces, nations, municipalities) {
				d.resolve({
					genders: genders[0].rows,
					types: types[0].rows,
					groups: groups[0].rows,
					provinces: provinces[0].rows,
					nations: nations[0].rows,
					municipalities: detail.birthProvinceId ? municipalities[0].rows : {}
				});
			})
			.fail(function() {
				d.reject();
			});
			return d;
		},
		createOrEditCallback: function() {
			$('#birthday').datepicker({endDate: '+0d' });
			$('#birthNationId').select2();
			$('#birthProvinceId').select2();
			$('#birthMunicipalityId').select2();

			if ($('#birthProvinceId').val() != undefined && $('#birthProvinceId').val() > 0) {
				$('#birthNationId').parents('.form-group').hide();
				$('#birthProvinceId').parents('.form-group').show();
				$('#birthMunicipalityId').parents('.form-group').show();
				$('#birthPlace').parents('.form-group').hide();
			} else {
				if ($('#birthplaceselector').val() == 1) {
					$('#birthNationId').parents('.form-group').hide();
					$('#birthProvinceId').parents('.form-group').show();
					$('#birthMunicipalityId').parents('.form-group').show();
					$('#birthPlace').parents('.form-group').hide();
				} else {
					$('#birthNationId').parents('.form-group').show();
					$('#birthProvinceId').parents('.form-group').hide();
					$('#birthMunicipalityId').parents('.form-group').hide();
					$('#birthPlace').parents('.form-group').show();
				}
			}
		},
		beforeSaveCallback: function(data) {
			//fix dates
			delete data.birthplaceselector;
			data.birthday = moment(data.birthday, 'L').format('YYYY-MM-DD');
			return data;
		},
		apiRoot: EWO.config.apiUrlPrefix + '/mayors',
		routingRoot: '/election/:electionId/constituency/:constituencyId/mayors'
	});

	//events
	$(document).on('change', '.js-electionMayors #birthplaceselector', function(ev) {
		var el = $(ev.currentTarget);
		ev.preventDefault();

		if (el.val() === '1') {
			$('#birthPlace').parents('.form-group').hide();
			$('#birthNationId').parents('.form-group').hide();
			$('#birthNationId').val(1);
			$('#birthProvinceId').parents('.form-group').show();
			$('#birthMunicipalityId').parents('.form-group').show();
			$('#birthPlace').val('');
		} else {
			$('#birthPlace').parents('.form-group').show();
			$('#birthNationId').parents('.form-group').show();
			$('#birthProvinceId').parents('.form-group').hide();
			$('#birthMunicipalityId').parents('.form-group').hide();
			$('#birthNationId').val('').trigger('change');
			$('#birthProvinceId').val('').trigger('change');
			$('#birthMunicipalityId').val('').trigger('change');
		}
	});

	$(document).on('change', '.js-electionMayors #birthProvinceId', function(ev) {
		var el = $(ev.currentTarget);
		ev.preventDefault();
		if ($(el).val() != undefined && $(el).val() != '') {
			muniOptions($(el).val());
		}
	});

	var muniOptions = function(provId) {
		$.ajax({
			url: EWO.config.apiUrlPrefix + '/types/municipalitybyprovince?provId=' + provId,
			method: 'GET',
			handled: true
		})
		.done(function(response) {
			$('#birthMunicipalityId').select2('enable');
			$('#birthMunicipalityId').empty().trigger('change');
			$('#birthMunicipalityId').select2({data: response.rows.map(function(e) {return {id: e.id, text: e.desc};})});
			$('#birthMunicipalityId').prepend('<option selected=""></option>').select2({placeholder: i18next.t('Select a municipality')});
			$('#birthMunicipalityId').trigger('change');
		});
	};
	// module startup

	//setup routing
})();
