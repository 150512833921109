(function() {
	// globals
	window.EWO.Election = window.EWO.Election || {};


	var constituencyId;

	// module functions
	EWO.Election.renderImports = function(ctx) {
		var urlQueryString = new URLSearchParams(ctx.querystring);
		var filter = Object.fromEntries(urlQueryString);
		$.extend(filter, ctx.params);

		$.when(
			ejs.preloadTemplate('/app/election/imports/templates/imports.ejs'),
		)
		.done(function(templateUrl) {
			$('.js-election-container').html(ejs.rr(templateUrl, {

			}));

		});

		constituencyId = filter.constituencyId;

			
	};


	//events

	// module startup


	//setup routing
	page('/election/:electionId/constituency/:constituencyId/imports', window.EWO.Election.renderImports);

})();
