(function() {
	// globals
	window.EWO.Election = window.EWO.Election || {};


	// module functions
	EWO.Election.renderManualSetCouncil = function(ctx) {
		var urlQueryString = new URLSearchParams(ctx.querystring);
		var filter = Object.fromEntries(urlQueryString);
		$.extend(filter, ctx.params);

		$.when(
			ejs.preloadTemplate('/app/election/manualSetCouncil/templates/manualSetCouncil.ejs'),
			$.get(EWO.config.apiUrlPrefix + '/council/custom', filter),
			$.get(EWO.config.apiUrlPrefix + '/constituencies/municipalitiesforconstituencies', filter)
		)
		.done(function(templateUrl, res, municipalities) {
			$('.js-election-container').html(ejs.rr(templateUrl, {res: res[0], data: municipalities, filter: filter}));

			$('.js-editform').validate({
				rules: {
					computedVotes: {
						required: true,
						number: true
					},
					numSeats: {
						required: true,
						number: true
					},
					numSeatsWithRest: {
						required: true,
						number: true
					}
				}
			});
		});
	};

	//events
	$(document).on('submit', '.js-electionManualSetCouncil .js-filterform', function(ev) {
		var el = $(ev.currentTarget);
		ev.preventDefault();
		var filter = el.serialize();
		page(document.location.pathname + (filter ? '?' + filter : ''));
	});

	$(document).on('click', '.js-electionManualSetCouncil .js-save-council', function(ev) {
		var data = {};
		data.id = $('input[name=id]').val();
		data.simulationId = $('input[name=simulationId]').val();
		data.lists = [];

		data.lists = $('.list').map(function(i, e) {
			return {
				id: Number($(e).attr('data-id')),
				computedVotes: Number($(e).parent().find('#computedVotes_' + $(e).attr('data-id')).val()),
				numSeats: Number($(e).parent().find('#numSeats_' + $(e).attr('data-id')).val()),
				numSeatsWithRest: Number($(e).parent().find('#numSeatsWithRest_' + $(e).attr('data-id')).val()),
				members: $('.member_' + $(e).attr('data-id')).map(function(i, f) {
					return {
						id: Number($(f).attr('data-id')),
						elected: Number($(f).parent().find('input[name="elected_' + $(f).attr('data-id') + '"]:checked').length > 0 ? $(f).parent().find('input[name="elected_' + $(f).attr('data-id') + '"]:checked').val() : 0)
					};
				}).toArray()
			};
		}).toArray();


		var firstEl = {
			id: -1,
			num: 0,
			members: {}
		};

		firstEl.members = $('.mayor').map(function(i, e) {
			return {
				id: Number($(e).attr('data-id')),
				elected: Number($(e).parent().find('input[name="elected"]:checked').length > 0 ? $(e).parent().find('input[name="elected"]:checked').val() : 0)
			};
		}).toArray();

		data.lists.unshift(firstEl);
		$.ajax({
			type: 'PUT',
			url: EWO.config.apiUrlPrefix + '/council/setcustom',
			data: JSON.stringify(data),
			contentType: 'application/json; charset=UTF-8'
		})
		.done(function() {
			page(document.location.pathname);
		});
	});

	$(document).on('click', '.js-electionManualSetCouncil .js-publish', function() {
		var filterForm = $('.js-electionManualSetCouncil .js-filterform');
		var expFilter = filterForm.serialize();
		$.ajax({
			type: 'POST',
			url: EWO.config.apiUrlPrefix + '/council/publish?' + expFilter,
			contentType: 'application/json; charset=UTF-8'
		})
		.done(function() {
			page(document.location.pathname);
		});
	});


	// module startup


	//setup routing
	page('/election/:electionId/constituency/:constituencyId/setCouncil', window.EWO.Election.renderManualSetCouncil);
})();
