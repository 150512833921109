(function() {
	// globals
	window.EWO.Election = window.EWO.Election || {};

	// module functions
	EWO.Election.renderStatisticsPref = function(ctx) {
		var urlQueryString = new URLSearchParams(ctx.querystring);
		var filter = Object.fromEntries(urlQueryString);
		$.extend(filter, ctx.params);

		$.when(
			ejs.preloadTemplate('/app/election/statisticsPref/templates/statisticsPref.ejs'),
			$.get(EWO.config.apiUrlPrefix + '/survey/statisticsPreferences', filter),
			$.get(EWO.config.apiUrlPrefix + '/constituencies/municipalitiesforconstituencies', filter),
			$.get(EWO.config.apiUrlPrefix + '/constituencies/listsforconstituencies', filter)
		)
		.done(function(templateUrl, list, municipalities, lists) {
			$('.js-election-container').html(ejs.rr(templateUrl, {
				list: list[0],
				data: {
					muni: municipalities[0].rows,
					constlist: lists[0].rows
				},
				filter: filter
			}));
		});
	};

	//events
	$(document).on('submit', '.js-electionStatisticsPref .js-filterform', function(ev) {
		var el = $(ev.currentTarget);
		ev.preventDefault();
		var filter = el.serialize();
		page(document.location.pathname + (filter ? '?' + filter : ''));
	});

	$(document).on('click', '.js-electionStatisticsPref .js-csv-export', function() {
		var filterForm = $('.js-electionStatisticsPref .js-filterform');
		var expFilter = filterForm.serialize();
		window.location.href = EWO.config.apiUrlPrefix + '/survey/statisticsPreferencesCsvExport?' + expFilter;
	});

	// module startup

	//setup routing
	page('/election/:electionId/constituency/:constituencyId/expPrefvotes', window.EWO.Election.renderStatisticsPref);

})();
