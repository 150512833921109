(function() {
	// globals
	window.EWO.Election = window.EWO.Election || {};


	var constituencyId;

	// module functions
	EWO.Election.renderElectionEvents = function(ctx) {
		var urlQueryString = new URLSearchParams(ctx.querystring);
		var filter = Object.fromEntries(urlQueryString);
		$.extend(filter, ctx.params);

		$.when(
			ejs.preloadTemplate('/app/election/electionEvents/templates/electionEvents.ejs'),
			$.get(EWO.config.apiUrlPrefix + '/electionEvents', filter),
			$.get(EWO.config.apiUrlPrefix + '/electionevents/' + filter.electionId),
			$.get(EWO.config.apiUrlPrefix + '/constituencies/municipalitiesforconstituencies', filter),
		)
		.done(function(templateUrl, list, events, municipalities) {
			$('.js-election-container').html(ejs.rr(templateUrl, {
				list: list[0],
				events: events,
				data: municipalities,
				filter: filter
			}));

			$('.sortable').DataTable({'paging': false, 'lengthChange': false, 'info': false });
			$('[data-toggle="tooltip"]').tooltip(); 
			$('#municipalityId').select2();
			if(filter.eventId != undefined && filter.eventId != "")
			{
				$('#missing').removeAttr('disabled');
			}
			else
			{
				$('#missing').attr('disabled', 'disabled');
			}
		});

		constituencyId = filter.constituencyId;

		
	};

	EWO.Election.getCompleted = function(list){
		var totCompleted = 0;

		for (var j = 0; j < list.rows.length; j++){
			var item = list.rows[j];
			for (var k = 0; k < item.items.length; k++) {
				var element = item.items[k];
				if(element.status > 0)
				{
					totCompleted++;
				}
			}
		}

		return totCompleted;
	}


	//events
	$(document).on('submit', '.js-electionElectionEvents .js-filterform', function(ev) {
		var el = $(ev.currentTarget);
		ev.preventDefault();
		var filter = el.serialize();
		page(document.location.pathname + (filter ? '?' + filter : ''));
	});

	$(document).on('click', '.js-electionElectionEvents .js-eventRedirect', function(ev) {

		var el = $(ev.currentTarget);
			ev.preventDefault();

		var sectionNum = el.attr('data-sectionNum');
		var electionId = el.attr('data-electionId');
		var eventTypeId = el.attr('data-eventTypeId');
		var constituencyId = el.attr('data-constituencyId');
		var municipalityId = el.attr('data-municipalityId');
		var param = el.attr('data-param');

		//constitution
		if(eventTypeId != undefined && eventTypeId == 1){
			location.href = '/election/'+electionId+'/constituency/'+constituencyId+'/people?&roleId=&municipalityId='+municipalityId+'&desc=&sectionNum='+sectionNum+'&sectionDesc=';
		}

		//opening
		if(eventTypeId != undefined && eventTypeId == 2){
			location.href = '/election/'+electionId+'/constituency/'+constituencyId+'/constitution?&eventTypeId=&municipalityId='+municipalityId+'&desc=&sectionNum='+sectionNum+'&sectionDesc=';
		}

		//turnouts
		if(eventTypeId != undefined && eventTypeId == 3){
			location.href = '/election/'+electionId+'/constituency/'+constituencyId+'/sectCommunications?municipalityId='+municipalityId+'&type='+param+'&sectionNum='+sectionNum+'&sectionDesc=';
		}

		//listvotes
		if(eventTypeId != undefined && eventTypeId == 4){
			location.href = '/election/'+electionId+'/constituency/'+constituencyId+'/listvotes?municipalityId='+municipalityId+'&status=&sectionNum='+sectionNum+'&sectionDesc=';
		}

		//preferences
		if(eventTypeId != undefined && eventTypeId == 5){
			location.href = '/election/'+electionId+'/constituency/'+constituencyId+'/prefvotes?municipalityId='+municipalityId+'&status=&sectionNum='+sectionNum+'&sectionDesc=';
		}

	});

	$(document).on('change', '.js-electionElectionEvents #eventId', function(ev) {
		var el = $(ev.currentTarget);
		ev.preventDefault();

		if (el.val() === undefined || el.val() === "") {
			$('#missing').attr('disabled', 'disabled');
		}
		else
		{
			$('#missing').removeAttr('disabled');
		}
	});

	// module startup

	//setup routing
	page('/election/:electionId/constituency/:constituencyId/electionEvents', window.EWO.Election.renderElectionEvents);

})();
