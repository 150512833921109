(function() {
	// globals
	window.EWO.Election = window.EWO.Election || {};

	// module functions
	EWO.MD({
		global: EWO.Election,
		name: 'electionMuniCommunications',
		containerClass: 'js-electionMuniCommunications',
		attachToElement: '.js-election-container',
		listTemplate: '/app/election/muniCommunications/templates/muniCommunications.ejs',
		createOrEditTemplate: '/app/election/muniCommunications/templates/muniCommunicationsCreateOrEdit.ejs',
		createOrEditValidationRules: {
			rules: {
				maleVoters: {
					required: true,
					number: true
				},
				femaleVoters: {
					required: true,
					number: true
				},
				totalVoters: {
					required: true,
					number: true
				}
			}
		},
		listAdditionalData: function(filter) {
			console.log(filter);
			var d = $.Deferred();
			$.when(
				$.get(EWO.config.apiUrlPrefix + '/constituencies/municipalitiesforconstituencies?constituencyId=' + filter.constituencyId + '&electionId=' + filter.electionId),
				$.get(EWO.config.apiUrlPrefix + '/communications/types?electionId=' + filter.electionId)
			)
			.done(function(municipalities, types) {
				d.resolve({
					municipalities: municipalities[0].rows,
					types: types[0].rows
				});
			})
			.fail(function() {
				d.reject();
			});
			return d;
		},
		listCallback: function() {
			$('#municipalityId').select2();
		},
		datatablesOptions: {
			pageLength: 100,
			lengthMenu: [[50, 100, 500, -1], [50, 100, 500, "Tutti"] ]
		},
		apiRoot: EWO.config.apiUrlPrefix + '/communications/municipalities',
		apiRootForUpdate: EWO.config.apiUrlPrefix + '/communications',
		routingRoot: '/election/:electionId/constituency/:constituencyId/muniCommunications'
	});

	// module startup

	//setup routing
})();
