(function() {
	// globals
	window.EWO.Election = window.EWO.Election || {};

	EWO.MD({
		global: EWO.Election,
		name: 'electionPeople',
		containerClass: 'js-electionPeople',
		attachToElement: '.js-election-container',
		listTemplate: '/app/election/people/templates/people.ejs',
		createOrEditTemplate: '/app/election/people/templates/peopleCreateOrEdit.ejs',
		createOrEditValidationRules: {
			rules: {
				username: 'required',
				firstname: 'required',
				familyname: 'required',
				role: 'required',
				language: {
					required: function(element) {
						return $('#role').val() === '1';
					}
				},
				phone: {
					required: function(element) {
						return $('#role').val() === '1';
					}
				},
				email: {
					required: function(element) {
						return $('#role').val() === '1';
					}
				},
				birthplaceselector: {
					required: function(element) {
						return $('#role').val() === '1';
					}
				},
				birthMunicipalityId: {
					required: function(element) {
						return ($('#role').val() === '1' && $('#birthplaceselector').val() === '1' );
					}
				},
				birthPlace: {
					required: function(element) {
						return ($('#role').val() === '1' && $('#birthplaceselector').val() === '2');
					}
				},
				birthNationId: {
					required: function(element) {
						return ($('#role').val() === '1');
					}
				},
				birthday: {
					required: function(element) {
						return $('#role').val() === '1';
					}
				},
				sectionId: 'required'
			}
		},
		listAdditionalData: function(filter) {
			return $.get(EWO.config.apiUrlPrefix + '/constituencies/municipalitiesforconstituencies?constituencyId=' + filter.constituencyId + '&electionId=' + filter.electionId);
		},
		listCallback: function() {
			$('#municipalityId').select2();
		},
		createOrEditAdditionalData: function(id, filter, qs, detail) {
			delete filter.id;
			var d = $.Deferred();
			$.when(
				$.get(EWO.config.apiUrlPrefix + '/types/allprovinces'),
				$.get(EWO.config.apiUrlPrefix + '/types/nations'),
				$.get(EWO.config.apiUrlPrefix + '/types/municipalitybyprovince?provId=' + (detail ? (detail.birthProvinceId != undefined ? detail.birthProvinceId : '-1') : '-1')),
				$.get(EWO.config.apiUrlPrefix + '/constituencies/municipalitiesforconstituencies?constituencyId=' + filter.constituencyId + '&electionId=' + filter.electionId),
				$.get(EWO.config.apiUrlPrefix + '/Municipalities/sectionsformunicipality?constituencyId=' + filter.constituencyId + '&electionId=' + filter.electionId + '&municipalityId=' + (detail ? (detail.municipalityId != undefined ? detail.municipalityId : '') : ''))
			)
			.done(function(provinces, nations, municipalities, muniForSection, sections) {
				d.resolve({
					provinces: provinces[0].rows,
					nations: nations[0].rows,
					municipalities: detail.birthProvinceId ? municipalities[0].rows : {},
					muniForSection: muniForSection[0].rows,
					sections: sections[0].rows
				});
			})
			.fail(function() {
				d.reject();
			});
			return d;
		},
		createOrEditCallback: function() {
			$('#birthday').datepicker({endDate: '+0d' });
			$('#birthNationId').select2();
			$('#birthProvinceId').select2();
			$('#birthMunicipalityId').select2();
			$('#muniForSection').select2();
			$('#sectionId').select2();


			if ($('#birthProvinceId').val() != undefined && $('#birthProvinceId').val() > 0) {
				$('#birthNationId').parents('.form-group .col-4').hide();
				$('#birthProvinceId').parents('.form-group .col-4').show();
				$('#birthMunicipalityId').parents('.form-group .col-4').show();
				$('#birthPlace').parents('.form-group .col-4').hide();
			} else {
				if ($('#birthplaceselector').val() == 1) {
					$('#birthNationId').parents('.form-group .col-4').hide();
					$('#birthProvinceId').parents('.form-group .col-4').show();
					$('#birthMunicipalityId').parents('.form-group .col-4').show();
					$('#birthPlace').parents('.form-group .col-4').hide();
				} else {
					$('#birthNationId').parents('.form-group .col-4').show();
					$('#birthProvinceId').parents('.form-group .col-4').hide();
					$('#birthMunicipalityId').parents('.form-group .col-4').hide();
					$('#birthPlace').parents('.form-group .col-4').show();
				}
			}
		},
		beforeSaveCallback: function(data) {
			//fix dates
			delete data.birthplaceselector;
			if(data.birthday != "" && data.birthday != undefined)
			{
				data.birthday = moment(data.birthday, 'L').format('YYYY-MM-DD');
			}
			return data;
		},
		datatablesOptions: {
			pageLength: 100,
			lengthMenu: [[50, 100, 500, -1], [50, 100, 500, "Tutti"] ]
		},
		apiRoot: EWO.config.apiUrlPrefix + '/people',
		routingRoot: '/election/:electionId/constituency/:constituencyId/people'
	});

	// events
	$(document).on('change', '.js-electionPeople #birthplaceselector', function(ev) {
		var el = $(ev.currentTarget);
		ev.preventDefault();

		if (el.val() === '1') {
			$('#birthPlace').parents('.form-group .col-4').hide();
			$('#birthNationId').parents('.form-group .col-4').hide();
			$('#birthNationId').val(1);
			$('#birthProvinceId').parents('.form-group .col-4').show();
			$('#birthMunicipalityId').parents('.form-group .col-4').show();
			$('#birthPlace').val('');
		} else {
			$('#birthPlace').parents('.form-group .col-4').show();
			$('#birthNationId').parents('.form-group .col-4').show();
			$('#birthProvinceId').parents('.form-group .col-4').hide();
			$('#birthMunicipalityId').parents('.form-group .col-4').hide();
			$('#birthNationId').val('').trigger('change');
			$('#birthProvinceId').val('').trigger('change');
			$('#birthMunicipalityId').val('').trigger('change');
		}
	});

	$(document).on('change', '.js-electionPeople #birthProvinceId', function(ev) {
		var el = $(ev.currentTarget);
		ev.preventDefault();
		if ($(el).val() != undefined && $(el).val() != '') {
			muniOptions($(el).val());
		}
	});

	var muniOptions = function(provId) {
		$.ajax({
			url: EWO.config.apiUrlPrefix + '/types/municipalitybyprovince?provId=' + provId,
			method: 'GET',
			handled: true
		})
		.done(function(response) {
			$('#birthMunicipalityId').select2('enable');
			$('#birthMunicipalityId').empty().trigger('change');
			$('#birthMunicipalityId').select2({data: response.rows.map(function(e) {return {id: e.id, text: e.desc};})});
			$('#birthMunicipalityId').prepend('<option selected=""></option>').select2({placeholder: i18next.t('Select a municipality')});
			$('#birthMunicipalityId').trigger('change');
		});
	};

	$(document).on('change', '.js-electionPeople #muniForSection', function(ev) {
		var el = $(ev.currentTarget);
		ev.preventDefault();
		if ($(el).val() != undefined && $(el).val() != '') {
			var constituencyId = $('#constituencyId').val();
			var electionId = $('#electionId').val();
			sectionOptions($(el).val(), constituencyId, electionId);
		}
	});

	var sectionOptions = function(muniId, constId, elecId) {
		$.ajax({
			url: EWO.config.apiUrlPrefix + '/municipalities/sectionsformunicipality?constituencyId=' + constId + '&electionId=' + elecId + '&municipalityId=' + muniId,
			method: 'GET',
			handled: true
		})
		.done(function(response) {
			$('#sectionId').select2('enable');
			$('#sectionId').empty().trigger('change');
			$('#sectionId').select2({data: response.rows.map(function(e) {return {id: e.id, text: e.desc};})});
			$('#sectionId').prepend('<option selected=""></option>').select2({placeholder: i18next.t('Select a section')});
			$('#sectionId').trigger('change');
		});
	};

	
	$(document).on('click', '.js-electionPeople .js-csv-export', function() {
		var filterForm = $('.js-electionPeople .js-filterform');
		var expFilter = filterForm.serialize();
		window.location.href = EWO.config.apiUrlPrefix + '/people/exportCsv?' + expFilter;
	});

	$(document).on('click', '.js-electionPeople .js-export', function(ev) {
		var el = $(ev.currentTarget);
		var type = $(el).data('type');
		var filterForm = $('.js-electionPeople .js-filterform');
		var expFilter = filterForm.serialize();
		window.location.href = EWO.config.apiUrlPrefix + '/people/export?format='+ type + '&' + expFilter;
	});

	$(document).on('click', '.js-electionPeople .js-passwordreset', function(ev) {
		ev.preventDefault();
		var el = $(ev.currentTarget);
		var id = el.attr('data-id');
		var constituencyId = el.attr('data-constituencyId');
		var electionId = el.attr('data-electionId');
		var sectionId = el.attr('data-sectionId');

		var data = {
			id: id,
			electionId: electionId,
			constituencyId: constituencyId,
			sectionId: sectionId
		};

		$.ajax({
			url: EWO.config.apiUrlPrefix + '/people/resetcredentials',
			type: 'PUT',
			data: JSON.stringify(data),
			contentType: 'application/json; charset=UTF-8'
		})
		.done(function(response) {
			PNotify.success(i18next.t('SentCredentials'));
		})
		.fail(function(e) {
			PNotify.error(e.responseText);
		});
	});

	// module startup

	//setup routing
})();
