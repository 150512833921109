(function() {
	// globals
	window.EWO.Users = {};

	//module functions
	EWO.MD({
		global: EWO.Users,
		name: 'notifications',
		containerClass: 'js-notifications',
		listTemplate: '/app/info/notifications/templates/notifications.ejs',
		createOrEditTemplate: '/app/info/notifications/templates/notificationsCreateOrEdit.ejs',
		createOrEditValidationRules: {
			rules: {
				text: 'required',
				expire: 'required'
			}
		},
		datatablesOptions: {
			ordering: false,
			paging: false
		},
		createOrEditAdditionalData: function(id) {
			return $.get(EWO.config.apiUrlPrefix + '/elections');
		},
		createOrEditCallback: function(id) {
			$('#electionId').select2();
			$('#municipalityIds').select2();
			$('#expire').select2();
		},
		beforeSaveCallback: function(data) {
			//fix dates
			if (data.expire == 1) {
				data.expireDate = moment().add(5, 'minutes');
			} else if (data.expire == 2) {
				data.expireDate = moment().add(15, 'minutes');
			} else if (data.expire == 3) {
				data.expireDate = moment().add(1, 'h');
			} else if (data.expire == 4) {
				data.expireDate = moment().add(3, 'h');
			} else if (data.expire == 5) {
				data.expireDate = moment().add(6, 'h');
			} else if (data.expire == 6) {
				data.expireDate = moment().add(1, 'days');
			} else if (data.expire == 7) {
				data.expireDate = moment().add(7, 'days');
			}

			data.expireDate = data.expireDate.format('YYYY-MM-DDTHH:mm:ss');

			if (data.municipalityIds && !$.isArray(data.municipalityIds)) {
				data.municipalityIds = [data.municipalityIds];
			}

			delete data.expire;
			delete data.electionId;

			return data;
		},
		apiRoot: EWO.config.apiUrlPrefix + '/notifications',
		routingRoot: '/info/notifications'
	});

	var muniOptions = function(eleId) {
		$.ajax({
			url: EWO.config.apiUrlPrefix + '/municipalities/municipalitiesforelection?electionId=' + eleId,
			method: 'GET',
			handled: true
		})
		.done(function(response) {
			$('#municipalityIds').empty().trigger('change');
			$('#municipalityIds').select2({data: response.rows.map(function(e) {return {id: e.id, text: e.desc};})});
			$('#municipalityIds').trigger('change');
		});
	};

	//events
	$(document).on('change', '.js-notifications #electionId', function(ev) {
		var el = $(ev.currentTarget);
		ev.preventDefault();
		if ($(el).val() != undefined && $(el).val() != '') {
			muniOptions($(el).val());
		}
	});

	//routing
})();
